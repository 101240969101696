import React, { Fragment } from "react";
import {
  Loader,
  TabsCustom,
  TabsCustomHeader,
  TabsCustomMenu,
  TabsCustomBody,
  TabsCustomItem,
  FullModal,
} from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";
import moment from "moment";

import {
  getFolder,
  updateDataFolder,
  updateFolderState,
  getFolderState,
  getOptions,
  getParties as getPartiesServer,
  updateCompany,
} from "../../requests/folder";
import { getFiles, deleteFile } from "../../requests/folder/file";

import Administrative from "./folder/Administrative";
import FilePreparation from "./folder/FilePreparation";
import Histovec from "./folder/Histovec";
import RecallFacts from "./folder/RecallFacts";
import Expertises from "./folder/Expertises";
import ProtocolComplaints from "./folder/ProtocolComplaints";
import Reports from "./folder/Reports";

import Header from "../../components/folder/Header";
import LeftBar from "../../components/folder/LeftBar";
import ModalCall from "../../components/folder/ModalCall";
import ModalAppointment from "../../components/folder/ModalAppointment";
import ModalLibrary from "../../components/folder/ModalLibrary";
import ModalPDF from "../../components/folder/general/ModalPDF";
import ModalTechnicalAdvice from "../../components/folder/ModalTechnicalAdvice";
import ModalBackupStatus from "../../components/folder/ModalBackupStatus";

import {
  getParty,
  getParties,
  getPartiesOptions,
  dataParts,
} from "../../utils/general";

import AuthContext from "../../context/AuthContext";
import Evaluation from "./folder/Evaluation";
import ModalHistoryPostal from "../../components/home/ModalHistoryPostal";

export default class Folder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      save: 0,
      saveTimer: {},
      folderId: null,
      reference: null,
      tabsIdArray: [
        { title: "Administratif" },
        { title: "Prépa. dossier", isRequireNetwork: true },
        { title: "Rappel des faits" },
        { title: "Chiffrage" },
        { title: "Expertises" },
        { title: "Protocole / Réclamation / Proposition" },
        { title: "Rapports" },
      ],
      tabsId: 0,
      type: null,
      shortType: null,
      stateId: null,
      stateName: null,
      stateColor: null,
      files: [],
      actions: [],
      calls: [],
      appointments: [],
      technicalAdvices: [],
      // DATA FOLDER
      companyId: null,
      companyId2: null,
      dataAdministrative: {},
      dataFilePreparation: {},
      dataRecallFacts: [],
      dataExpertise: [],
      dataProtocolComplaints: {},
      dataReports: [],
      dataEvaluation: {},
      dataOther: {},
      // OTHER DATA FOLDER
      dateAppointment: null,
      hourAppointment: null,
      // MODAL
      isModalBackupStatus: false,
      isModalCall: false,
      isModalAppointment: false,
      isModalHistoryPostal: false,
      isModalLibrary: false,
      isModalLibraryGLA: false,
      modalLibrarySetFiles: null,
      modalLibraryFilenameEdit: null,
      isModalTechnicalAdvice: false,
      modalTechnicalAdviceIsReport: false,
      modalTechnicalAdviceCallback: () => {},
      isModalPDF: false,
      modalPDFBase64: "",
      modalPDFName: "",
      // DISPLAY CARD ACTIONS FOLDER
      isActions: localStorage.getItem("statusActions") === "0" ? false : true,
      isHistory: localStorage.getItem("statusHistory") === "1" ? true : false,
      histories: [],
      currentPosition: 0,
      interfaceCabinet: null,
      interfaceDossier: null,
      interfaceVehicule: null,
      isDisconnected: false,
      partiesServer: [],
      isLock: false,
      lockUser: null,
    };

    this.saveCounter = {};
  }

  static contextType = AuthContext;

  componentDidMount() {
    this.getFolder();

    this.observer = new MutationObserver((mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (mutation.type === "childList") {
          if (this.state.shortType === "RC") {
            this.replaceWordInDOM(document.body, {
              Lésé: "Assuré",
              Adverse: "Lésé Adverse",
            });
          }
        }
      });
    });

    this.observer.observe(document.body, { childList: true, subtree: true });

    window.openFolder(
      this.props.match.params.token,
      this.context.name,
      (name) => {
        document.location.hash = "/home";
        window.alert(
          `Dossier en cours d'utilisation par un autre utilisateur - ${name}`
        );
      }
    );

    this.handleCheckOnline();
    this.onlineInterval = setInterval(this.handleCheckOnline, 5000);

    window.addEventListener("beforeunload", this.onBeforeUnload);
  }

  replaceWordInDOM(element, replacements) {
    if (element.childNodes.length) {
      if (
        element.className !== "editor-container" &&
        !["SCRIPT", "INPUT", "TEXTAREA"].includes(element.tagName)
      ) {
        element.childNodes.forEach((child) => {
          this.replaceWordInDOM(child, replacements);
        });
      }
    } else {
      if (!["SCRIPT", "INPUT", "TEXTAREA"].includes(element.tagName)) {
        const textContent = element.textContent;
        if (textContent) {
          Object.entries(replacements).forEach(([from, to]) => {
            if (from === "Lésé" && !element.textContent.includes("Adverse")) {
              element.textContent = element.textContent.replace(
                new RegExp(from, "g"),
                to
              );
            } else if (
              from === "Adverse" &&
              !element.textContent.includes("Lésé")
            ) {
              element.textContent = element.textContent.replace(
                new RegExp(from, "g"),
                to
              );
            }
          });
        }
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.onlineInterval);
    window.closeFolder(this.props.match.params.token);
    window.removeEventListener("beforeunload", this.onBeforeUnload);
    this.observer.disconnect();
  }

  onBeforeUnload = (e) => {
    if (this.state && this.state.save === 1) {
      e = e || window.event;

      // For IE and Firefox prior to version 4
      if (e) {
        e.returnValue = "Sure?";
      }

      // For Safari
      return "Sure?";
    }
  };

  handleCheckOnline = () => {
    if (!navigator.onLine && !this.state.isDisconnected) {
      this.setState({ isDisconnected: true });
    } else if (navigator.onLine && this.state.isDisconnected) {
      this.setState({ isDisconnected: false });
    }
  };

  getFiles = (callback = () => {}) => {
    getFiles(
      {
        reference: this.state.reference,
      },
      (result) => {
        if (result.success) {
          this.setState({ files: result.data }, callback);
        } else {
          callback();
        }
      }
    );
  };

  deleteFile(folderId, token, callback) {
    deleteFile({ folderId, token }, callback);
  }

  updateFiles = (files, callback = () => {}) => {
    this.setState(
      {
        files,
      },
      callback
    );
  };

  getFolder = () => {
    this.setState(
      {
        isLoad: true,
      },
      () => {
        getFolder(
          {
            token: this.props.match.params.token,
            isSoftware: this.context.isSoftware,
          },
          (result) => {
            if (result.success) {
              let dataProtocolComplaints = result.data.dataProtocolComplaints;

              if (
                dataProtocolComplaints.dataProtocol &&
                dataProtocolComplaints.dataProtocol.histories
              ) {
                dataProtocolComplaints.dataProtocol.histories =
                  dataProtocolComplaints.dataProtocol.histories.sort((a, b) => {
                    return (
                      moment(
                        `${a.informations.date} ${a.informations.hour}`,
                        "YYYY-MM-DD HH:mm"
                      ).toDate() -
                      moment(
                        `${b.informations.date} ${b.informations.hour}`,
                        "YYYY-MM-DD HH:mm"
                      ).toDate()
                    );
                  });
              }
              this.setState(
                {
                  isLoad: false,
                  folderId: result.data.id,
                  reference: result.data.reference,
                  companyId: result.data.companyId,
                  companyName: result.data.companyName,
                  companyId2: result.data.companyId2,
                  companyName2: result.data.companyName2,
                  type: result.data.type,
                  shortType: result.data.shortType,
                  stateId: result.data.stateId,
                  stateName: result.data.stateName,
                  stateColor: result.data.stateColor,
                  files: result.data.files,
                  dataAdministrative: result.data.dataAdministrative,
                  dataFilePreparation: result.data.dataFilePreparation,
                  dataRecallFacts: result.data.dataRecallFacts,
                  dataExpertise: result.data.dataExpertise,
                  dataProtocolComplaints,
                  dataReports: result.data.dataReports,
                  dataEvaluation: result.data.dataEvaluation,
                  dataOther: result.data.dataOther,
                  dateAppointment: result.data.dateAppointment,
                  hourAppointment:
                    result.data.hourAppointment === "Invalid date"
                      ? null
                      : result.data.hourAppointment,
                  interfaceCabinet: result.data.interfaceCabinet,
                  interfaceDossier: result.data.interfaceDossier,
                  interfaceVehicule: result.data.interfaceVehicule,
                  isLock:
                    result.data.lockUser &&
                    result.data.lockUser !== this.context.id,
                  lockUser: result.data.lockUser,
                },
                () => {
                  this.getFolderState();
                  this.getOptions();
                  this.getPartiesServer();

                  // for electronicSignature
                  if (result.isUpdateProtocol) {
                    this.updateDataFolder(
                      "dataProtocolComplaints",
                      this.state.dataProtocolComplaints,
                      () => {}
                    );
                  }

                  const lastTabs = parseInt(
                    localStorage.getItem(`${result.data.reference}-tabs`)
                  );

                  if (lastTabs) {
                    this.setState({
                      tabsId: lastTabs,
                    });
                  }
                }
              );
            } else {
              document.location.hash = "/home";
            }
          }
        );
      }
    );
  };

  getOptions = (functions = undefined) => {
    getOptions(
      (result) => {
        // actions - calls - appointments - technicalAdvices
        this.setState(result.data);
      },
      { folder: this.state.folderId },
      functions
    );
  };

  getFolderState = () => {
    getFolderState(
      {
        folderId: this.state.folderId,
      },
      (result) => {
        if (result.success) {
          this.setState({
            stateId: result.data.stateId,
            stateName: result.data.stateName,
            stateColor: result.data.stateColor,
          });
        }
      }
    );
  };

  getPartiesServer = () => {
    getPartiesServer((result) => {
      if (result.success) {
        this.setState({ partiesServer: result.data });
      } else {
        this.setState({ partiesServer: [] });
      }
    });
  };

  updatePartiesServer = (partiesServer) => {
    this.setState({ partiesServer });
  };

  openModalLibraryGLA = () => this.setState({ isModalLibraryGLA: true });

  openModalCall = () => this.setState({ isModalCall: true });

  openModalAppointment = () => this.setState({ isModalAppointment: true });

  openModalHistoryPostal = () => this.setState({ isModalHistoryPostal: true });

  openModalTechnicalAdvice = (isReport = false, callback = () => {}) => {
    this.setState({
      isModalTechnicalAdvice: true,
      modalTechnicalAdviceIsReport: isReport,
      modalTechnicalAdviceCallback: callback,
    });
  };

  openModalLibrary = (setFiles = null, filenameEdit = null) =>
    this.setState({
      isModalLibrary: true,
      modalLibrarySetFiles: setFiles,
      modalLibraryFilenameEdit: filenameEdit,
    });

  updateDataFolder = (
    name,
    data,
    callback = () => {},
    action = null,
    keyData = null,
    currentPosition = null
  ) => {
    const histories = [...this.state.histories];

    if (currentPosition === null) {
      histories.push({
        name,
        data: JSON.stringify(data),
        date: moment().format("YYYY-MM-DD HH:mm:ss"),
      });

      if (histories.length > 10) {
        histories.splice(0, 1);
      }
    }

    if (!this.saveCounter[name]) {
      this.saveCounter[name] = 0;
    }
    this.saveCounter[name] += 1;

    this.setState(
      {
        save: 1,
        histories,
        currentPosition: currentPosition ? currentPosition : 0,
        [name]: data,
      },
      () => {
        if (
          this.state.saveTimer[name] &&
          moment.duration(moment().diff(this.state.saveTimer[name])).seconds() <
            6
        ) {
          setTimeout(() => {
            if (this.saveCounter[name] === 1) {
              this.saveCounter[name] -= 1;
              this.resultUpdateDataFolder(name, callback, action, keyData);
            } else {
              this.saveCounter[name] -= 1;
              callback();
            }
          }, 3000);
        } else {
          this.saveCounter[name] -= 1;
          this.resultUpdateDataFolder(name, callback, action, keyData);
        }
      }
    );
  };

  resultUpdateDataFolder = (
    name,
    callback = () => {},
    action = null,
    keyData = null
  ) => {
    let saveTimer = this.state.saveTimer;
    saveTimer[name] = moment();
    this.setState({ saveTimer, save: 2 }, () => {
      let dataOther = this.state.dataOther;
      dataOther[`lastDate_${name}`] = moment().format("DD/MM/YYYY HH:mm:ss");

      updateDataFolder(
        this.state.folderId,
        name,
        this.state[name],
        dataOther,
        (result) => {
          if (result.success) {
            this.setState(
              {
                save: 3,
                actions:
                  action && result.actions
                    ? result.actions
                    : this.state.actions,
              },
              () => {
                localStorage.setItem(
                  `${this.state.folderId}_${this.state[name]}_statusUpdate`,
                  "ok"
                );
                callback(result);
              }
            );
          } else {
            this.setState({ save: 4 }, () => {
              localStorage.setItem(
                `${this.state.folderId}_${this.state[name]}_statusUpdate`,
                "error"
              );
              setTimeout(() => {
                if (
                  localStorage.getItem(
                    `${this.state.folderId}_${this.state[name]}_statusUpdate`
                  ) === "error"
                ) {
                  this.resultUpdateDataFolder(name, callback, action, keyData);
                }
              }, 15000);
              callback(result);
            });
          }
        },
        action,
        keyData
      );
    });
  };

  getDataTemplate = (recipient = null) => {
    const mission =
      this.state.dataAdministrative.dataAdministrativeInformation.mission;
    const dataVehicleIdentification =
      this.state.dataAdministrative.dataVehicleIdentification;

    const parties = getParties(this.state.dataAdministrative);

    const expert = getParty(parties, "Expert", "Lésé");
    const lese = getParty(parties, "Lésé");
    const garage = getParty(parties, "Réparateur");
    const mandant = getParty(parties, "Mandant");
    const assurance = getParty(parties, "Assureur");
    const gestionnaire = getParty(parties, "Gestionnaire");

    let to = {};
    if (recipient) {
      to = {
        toSociety: lese.society,
        toKind: lese.kind,
        toName: lese.name,
        toAddress: lese.address,
        toCity: lese.city,
        toZipCode: lese.zipCode,
      };

      if (parties.hasOwnProperty(recipient)) {
        to = {
          toSociety: parties[recipient].society,
          toKind: parties[recipient].kind,
          toName: parties[recipient].name,
          toAddress: parties[recipient].address,
          toCity: parties[recipient].city,
          toZipCode: parties[recipient].zipCode,
        };
      }
    }

    return {
      dateSystem: moment().format("DD/MM/YYYY"),
      dateMission: mission.dateMission,

      expertReference: mission.reference,
      sinisterDate: mission.dateSinister
        ? moment(mission.dateSinister).format("DD/MM/YYYY")
        : "",
      sinisterReference: mission.referenceSinister,
      licensePlate: dataVehicleIdentification[0].licensePlate,
      carBrand: dataVehicleIdentification[0].brand,

      expertKind: expert.kind,
      expertName: expert.name,
      expertAddress: expert.address,
      expertCity: expert.city,
      expertZipCode: expert.zipCode,
      expertCodeVE: expert.codeVE,

      leseKind: lese.kind,
      leseName: lese.name,
      leseAddress: lese.address,
      leseCity: lese.city,
      leseZipCode: lese.zipCode,

      garageName: garage.name,
      garageAddress: garage.address,
      garageCity: garage.city,
      garageZipCode: garage.zipCode,

      mandantName: mandant.name,
      mandantAddresies: mandant.address,
      mandantCity: mandant.city,
      mandantZipCodies: mandant.zipCode,

      assuranceName: assurance.name,
      assuranceAddress: assurance.address,
      assuranceCity: assurance.city,
      assuranceZipCode: assurance.zipCode,

      gestionnaireName: gestionnaire.name,
      gestionnaireAddress: gestionnaire.address,
      gestionnaireZipCode: gestionnaire.zipCode,
      gestionnaireCity: gestionnaire.city,

      isRC: this.state.type === "Responsabilité Civile et professionnelle",
      isPJ: this.state.type === "Protection Juridique",
      isATJ: this.state.type === "Assistance technique judiciaire",
      isAT: this.state.type === "Assistance technique",

      ...to,
    };
  };

  handleOpenModalPDF = (base64, name) => {
    this.setState({
      isModalPDF: true,
      modalPDFBase64: base64,
      modalPDFName: name,
    });
  };

  updateFolderState = (stateId) => {
    localStorage.setItem(`${this.state.reference}-stateId`, this.state.stateId);
    updateFolderState(stateId, this.state.folderId, () => {
      this.getFolderState();
    });
  };

  getData = (name, isArray = false) => {
    if (this.state.dataAdministrative.hasOwnProperty(name)) {
      return this.state.dataAdministrative[name];
    } else {
      return isArray ? [] : {};
    }
  };

  setData = (name, data, callback = () => {}) => {
    let tmpData = this.state.dataAdministrative;
    tmpData[name] = data;
    this.updateDataFolder("dataAdministrative", tmpData, callback);
  };

  updateCompany = () => {
    this.setState({ isLoad: true }, () => {
      updateCompany(
        { folderId: this.state.folderId, companyId: this.state.companyId2 },
        (result) => {
          if (result.success) {
            this.setState(
              {
                isLoad: false,
                companyId: this.state.companyId2,
                companyName: this.state.companyName2,
              },
              () => {}
            );
          } else {
            this.setState({ isLoad: false }, () => {
              alert("Une erreur est survenue");
            });
          }
        }
      );
    });
  };

  render() {
    if (!this.state.folderId) {
      return <Loader show={true} />;
    }
    const parties = getParties(this.state.dataAdministrative);
    const partiesOptions = getPartiesOptions(this.state.dataAdministrative);

    return (
      <>
        <AuthContext.Provider
          value={{
            ...this.context,
            folderId: this.state.folderId,
            reference: this.state.reference,
            companyId: this.state.companyId,
            companyName: this.state.companyName,
            companyId2: this.state.companyId2,
            companyName2: this.state.companyName2,
            updateCompany: this.updateCompany,
            type: this.state.type,
            shortType: this.state.shortType,
            dateAppointment: this.state.dateAppointment,
            hourAppointment: this.state.hourAppointment,
            parties,
            partiesOptions,
            getFiles: this.getFiles,
            updateFiles: this.updateFiles,
            files: this.state.files,
            dataAdministrative: this.state.dataAdministrative,
            handleOpenModalPDF: this.handleOpenModalPDF,
            openModalLibrary: this.openModalLibrary,
            getDataTemplate: this.getDataTemplate,
            updateDataFolder: this.updateDataFolder,
            updateFolderState: this.updateFolderState,
            stateId: this.state.stateId,
            technicalAdvices: this.state.technicalAdvices,
            openModalTechnicalAdvice: this.openModalTechnicalAdvice,
            interfaceCabinet: this.state.interfaceCabinet,
            interfaceDossier: this.state.interfaceDossier,
            interfaceVehicule: this.state.interfaceVehicule,
            isDisconnected: this.state.isDisconnected,
            partiesServer: this.state.partiesServer,
            updatePartiesServer: this.updatePartiesServer,
            isLock: this.state.isLock,
            lockUser: this.state.lockUser,
          }}
        >
          <Header
            {...this.props}
            save={this.state.save}
            reference={this.state.reference}
            openModalLibraryGLA={this.openModalLibraryGLA}
            openModalCall={this.openModalCall}
            openModalAppointment={this.openModalAppointment}
            openModalBackupStatus={() =>
              this.setState({ isModalBackupStatus: true })
            }
            openModalTechnicalAdvice={() => this.openModalTechnicalAdvice()}
            openModalHistoryPostal={() => this.openModalHistoryPostal()}
          />
          <div
            style={{
              marginTop: "-45px",
              padding: "15px",
            }}
          >
            <Row className="ml-10">
              <LeftBar
                actions={this.state.actions}
                stateColor={this.state.stateColor}
                stateName={this.state.stateName}
                histories={this.state.histories}
                currentPosition={this.state.currentPosition}
                isActions={this.state.isActions}
                isHistory={this.state.isHistory}
                updateActions={() =>
                  this.setState({ isActions: !this.state.isActions }, () => {
                    localStorage.setItem(
                      "statusActions",
                      this.state.isActions ? "1" : "0"
                    );
                  })
                }
                updateHistory={() =>
                  this.setState({ isHistory: !this.state.isHistory }, () => {
                    localStorage.setItem(
                      "statusHistory",
                      this.state.isHistory ? "1" : "0"
                    );
                  })
                }
                dataProtocolComplaints={this.state.dataProtocolComplaints}
                dataExpertise={this.state.dataExpertise}
                dataReports={this.state.dataReports}
              />
              <Col
                xs={12}
                lg={this.state.isActions || this.state.isHistory ? 9 : 12}
              >
                <TabsCustom>
                  <TabsCustomHeader className="m-0">
                    {this.state.tabsIdArray.map((element, key) => (
                      <TabsCustomMenu
                        key={key}
                        title={element.title}
                        disabled={
                          element.disabled ||
                          (this.state.isDisconnected &&
                            element.isRequireNetwork)
                        }
                        active={this.state.tabsId === key}
                        onClick={(e) => {
                          if (
                            element.disabled ||
                            (this.state.isDisconnected &&
                              element.isRequireNetwork)
                          ) {
                          } else {
                            this.setState(
                              {
                                tabsId: key,
                              },
                              () =>
                                localStorage.setItem(
                                  `${this.state.reference}-tabs`,
                                  key
                                )
                            );
                          }
                        }}
                      />
                    ))}
                  </TabsCustomHeader>
                  <TabsCustomBody noCard>
                    <TabsCustomItem active={this.state.tabsId === 0}>
                      <Administrative
                        dataAdministrative={this.state.dataAdministrative}
                        dataRecallFacts={this.state.dataRecallFacts}
                      />
                    </TabsCustomItem>
                    <TabsCustomItem active={this.state.tabsId === 1}>
                      <FilePreparation
                        dataFilePreparation={this.state.dataFilePreparation}
                      />
                    </TabsCustomItem>
                    <TabsCustomItem active={this.state.tabsId === 2}>
                      <RecallFacts
                        dataRecallFacts={this.state.dataRecallFacts}
                      />
                    </TabsCustomItem>
                    <TabsCustomItem active={this.state.tabsId === 3}>
                      <Evaluation data={this.state.dataEvaluation} />
                    </TabsCustomItem>
                    <TabsCustomItem active={this.state.tabsId === 4}>
                      <Expertises
                        data={this.state.dataExpertise}
                        dataRecallFacts={this.state.dataRecallFacts}
                        dataAdministrative={this.state.dataAdministrative}
                        dataEvaluation={this.state.dataEvaluation}
                      />
                    </TabsCustomItem>
                    <TabsCustomItem active={this.state.tabsId === 5}>
                      <ProtocolComplaints
                        dataExpertise={this.state.dataExpertise}
                        dataProtocolComplaints={
                          this.state.dataProtocolComplaints
                        }
                        dataRecallFacts={this.state.dataRecallFacts}
                      />
                    </TabsCustomItem>
                    <TabsCustomItem active={this.state.tabsId === 6}>
                      <Reports
                        data={this.state.dataReports}
                        dataRecallFacts={this.state.dataRecallFacts}
                        dataExpertise={this.state.dataExpertise}
                        dataAdministrative={this.state.dataAdministrative}
                        dataEvaluation={this.state.dataEvaluation}
                      />
                    </TabsCustomItem>
                  </TabsCustomBody>
                </TabsCustom>
              </Col>
            </Row>
          </div>
          {this.state.technicalAdvices ? (
            <ModalTechnicalAdvice
              show={this.state.isModalTechnicalAdvice}
              onClose={() =>
                this.setState({
                  isModalTechnicalAdvice: false,
                  modalTechnicalAdviceIsReport: false,
                  modalTechnicalAdviceCallback: () => {},
                })
              }
              modalTechnicalAdviceIsReport={
                this.state.modalTechnicalAdviceIsReport
              }
              modalTechnicalAdviceCallback={
                this.state.modalTechnicalAdviceCallback
              }
              technicalAdvices={this.state.technicalAdvices}
              getTechnicalAdvice={() =>
                this.getOptions([
                  {
                    name: "technicalAdvices",
                    url: "/folder/get-folder-technical-advice",
                  },
                ])
              }
            />
          ) : null}
          <ModalCall
            show={this.state.isModalCall}
            onClose={() => this.setState({ isModalCall: false })}
            getFolderActions={this.getFolderActions}
            getFolderCalls={this.getFolderCalls}
            calls={this.state.calls}
          />
          <ModalAppointment
            show={this.state.isModalAppointment}
            onClose={() => this.setState({ isModalAppointment: false })}
            getFolderActions={this.getFolderActions}
            getFolderAppointments={this.getFolderAppointments}
            appointments={this.state.appointments}
          />
          <ModalLibrary
            show={this.state.isModalLibrary}
            onClose={() =>
              this.setState({
                isModalLibrary: false,
                modalLibrarySetFiles: null,
                modalLibraryFilenameEdit: null,
              })
            }
            getFiles={this.getFiles}
            deleteFile={this.deleteFile}
            setFiles={this.state.modalLibrarySetFiles}
            filenameEdit={this.state.modalLibraryFilenameEdit}
          />
          <FullModal
            show={this.state.isModalLibraryGLA}
            onClose={() => this.setState({ isModalLibraryGLA: false })}
            classNameChildren="d-flex h-100 p-0"
          >
            <iframe
              title="biblio'tech GLA SOFTWARE"
              src="https://glasoftware.fr/#/library"
              style={{
                border: 0,
                height: "100%",
                width: "100%",
                overflow: "hidden",
              }}
            />
          </FullModal>
          <ModalPDF
            onClose={() =>
              this.setState({
                isModalPDF: false,
                modalPDFBase64: "",
                modalPDFName: "",
              })
            }
            show={this.state.isModalPDF}
            base64={this.state.modalPDFBase64}
            name={this.state.modalPDFName}
          />
          <ModalBackupStatus
            onClose={() =>
              this.setState({
                isModalBackupStatus: false,
              })
            }
            show={this.state.isModalBackupStatus}
            dataParts={dataParts}
            updateDataFolder={this.updateDataFolder}
            dataAdministrative={this.state.dataAdministrative}
            dataFilePreparation={this.state.dataFilePreparation}
            dataRecallFacts={this.state.dataRecallFacts}
            dataExpertise={this.state.dataExpertise}
            dataProtocolComplaints={this.state.dataProtocolComplaints}
            dataReports={this.state.dataReports}
            dataEvaluation={this.state.dataEvaluation}
            dataOther={this.state.dataOther}
          />
          <ModalHistoryPostal
            folderReference={this.state.reference}
            mailevaData={this.context.mailevaData}
            show={this.state.isModalHistoryPostal}
            onClose={() => this.setState({ isModalHistoryPostal: false })}
          />
          <Loader show={this.state.isLoad} />
        </AuthContext.Provider>
      </>
    );
  }
}
