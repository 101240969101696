import React from "react";
import {
  Card,
  FormInput,
  FormSelect,
  FormCheckbox,
} from "ui-kit-ck-consultant";

import AuthContext from "../../context/AuthContext";

export default class Filters extends React.Component {
  static contextType = AuthContext;

  render() {
    const agenciesSelect = this.context.agencies.filter(
      (agency) =>
        agency.regionId === Number(this.props.region) || !this.props.region
    );

    return (
      <Card className="mt-10 mt-20-xl" title="Rechercher un dossier">
        <FormInput
          className="mb-10"
          name="search"
          value={this.props.search}
          title="N° dossier ou immatriculation"
          onChange={this.props.handleInputChange}
        />
        <FormSelect
          className="mb-10"
          name="region"
          value={this.props.region}
          title="Régions"
          ignore={true}
          options={[
            { text: "Toutes les Régions", value: "" },
            ...this.context.regions,
          ]}
          onChange={(e) => {
            this.props.handleInputChange(e);

            if (!e.target.value) {
              this.props.handleInputChange({
                target: { value: "", name: "agency" },
              });
            }
          }}
        />
        <FormSelect
          className="mb-10"
          name="agency"
          value={this.props.agency}
          title="Agences"
          ignore={true}
          options={[
            { text: "Toutes les agences", value: "" },
            ...agenciesSelect,
          ]}
          onChange={this.props.handleInputChange}
        />
        <FormInput
          className="mb-10"
          name="client"
          value={this.props.client}
          title="Assuré"
          onChange={this.props.handleInputChange}
        />
        <FormSelect
          className="mb-10"
          name="expert"
          value={this.props.expert}
          title="Expert"
          ignore={true}
          options={[
            { text: "Toutes les experts", value: "" },
            ...this.props.experts,
          ]}
          onChange={this.props.handleInputChange}
        />
        <FormSelect
          className="mb-10"
          name="company"
          value={this.props.company}
          title="Compagnie"
          ignore={true}
          options={[
            { text: "Toutes les companies", value: "" },
            ...this.context.companies,
          ]}
          onChange={this.props.handleInputChange}
        />
        <FormSelect
          className="mb-10"
          name="state"
          value={this.props.state}
          title="Statut du dossier"
          ignore={true}
          options={[
            { text: "Toutes les statuts", value: "" },
            ...this.context.states,
          ]}
          onChange={this.props.handleInputChange}
        />
        <FormSelect
          className="mb-10"
          name="type"
          value={this.props.type}
          title="Type de dossier"
          ignore={true}
          options={[
            { text: "Tous les types", value: "" },
            ...this.context.types,
          ]}
          onChange={this.props.handleInputChange}
        />
        <FormCheckbox
          className="mb-10"
          name="isAllFolders"
          checked={this.props.isAllFolders}
          text="Tous les dossiers"
          onChange={this.props.handleCheckboxChange}
        />
        <FormCheckbox
          className="mb-10"
          name="isOfflineFolders"
          checked={this.props.isOfflineFolders}
          text="Dossiers hors connexion"
          onChange={this.props.handleCheckboxChange}
        />
        <FormCheckbox
          name="isLockFolders"
          checked={this.context.isLockFolders}
          text="Dossiers accessible"
          onChange={this.props.handleCheckboxChange}
        />
      </Card>
    );
  }
}
