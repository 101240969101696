import React from "react";
import { Col, Row } from "react-flexbox-grid";
import { ButtonIcon, FormCheckbox, FormInput } from "ui-kit-ck-consultant";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

import CustomCard from "../../general/CustomCard";

import AuthContext from "../../../../context/AuthContext";

export default class QualificationOfTheSinister extends React.Component {
  constructor(props) {
    super(props);
    this.displayName = "QualificationOfTheSinister";
  }

  static contextType = AuthContext;

  handleCheckboxChange = (e) => {
    const { name, checked } = e.target;

    let tmpData = this.props.data;
    tmpData.answers[name] = checked;

    this.setData(tmpData);
  };

  componentDidMount() {
    this.initData();
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
      this.initData();
    }
  }

  initData = () => {
    let objects = [
      { text: "Au cours de l’activité de l’assuré" },
      { text: "Après réparation et avant livraison du véhicule" },
      { text: "Après livraison et utilisation par le propriétaire" },
    ];

    if (
      this.props.data.hasOwnProperty("q1") ||
      this.props.data.hasOwnProperty("q2") ||
      this.props.data.hasOwnProperty("q3")
    ) {
      const tmpData = {
        objects,
        answers: {
          0: this.props.data.hasOwnProperty("q1") && this.props.data.q1,
          1: this.props.data.hasOwnProperty("q2") && this.props.data.q2,
          2: this.props.data.hasOwnProperty("q3") && this.props.data.q3,
        },
      };

      this.setData(tmpData);
    } else if (
      !this.props.data.hasOwnProperty("objects") ||
      !this.props.data.objects.length
    ) {
      let answers = {};

      const tmpData = {
        objects,
        answers,
      };

      this.setData(tmpData);
    }
  };

  setData = (tmpData) => {
    if (this.context.updateDataReport) {
      this.context.updateDataReport("qualificationOfTheSinister", tmpData);
    }
  };

  render() {
    return (
      <CustomCard title="Qualification du sinistre" name={this.displayName}>
        <Row>
          <Col xs={12}>
            <p>
              Le dommage est survenu le{" "}
              {moment(this.props.administrative.mission.dateSinister).format(
                "DD/MM/YYYY"
              )}
            </p>
          </Col>
          {this.props.data && this.props.data.hasOwnProperty("objects")
            ? this.props.data.objects.map((object, idx) => (
                <Col xs={12} key={idx}>
                  <div className="d-flex mb-10">
                    <FormCheckbox
                      className="mt-auto mb-auto"
                      text={object.isEdit ? "" : object.text}
                      name={idx}
                      checked={!!this.props.data.answers[idx]}
                      onChange={this.handleCheckboxChange}
                    />
                    {object.isEdit ? (
                      <>
                        <FormInput
                          className="ml-5 w-100 mt-auto mb-auto"
                          value={object.text}
                          onChange={({ target }) => {
                            let tmpData = this.props.data;
                            tmpData.objects[idx].text = target.value;
                            this.setData(tmpData);
                          }}
                        />
                        <ButtonIcon
                          className="red mt-auto mb-auto"
                          onClick={() => {
                            window.confirmCustom(
                              "Êtes-vous sûr de vouloir supprimet cet objet ?",
                              (result) => {
                                if (result) {
                                  let tmpData = this.props.data;
                                  delete tmpData.answers[idx];
                                  tmpData.objects.splice(idx, 1);
                                  this.setData(tmpData);
                                }
                              }
                            );
                          }}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </ButtonIcon>
                      </>
                    ) : null}
                  </div>
                </Col>
              ))
            : null}
        </Row>
        <div className="d-flex">
          <ButtonIcon
            small
            isBackground
            className="ml-auto"
            onClick={() => {
              let tmpData = this.props.data;
              tmpData.objects.push({ text: "", isEdit: true });
              this.setData(tmpData);
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
          </ButtonIcon>
        </div>
      </CustomCard>
    );
  }
}
