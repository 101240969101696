import React from "react";
import { Card } from "ui-kit-ck-consultant";

import Table from "./Table";

import AuthContext from "../../context/AuthContext";

export default class Body extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      pageLength: 10,
    };
  }

  static contextType = AuthContext;

  getColorLegend = (title, color) => {
    return (
      <div className="d-flex mb-10">
        <div
          style={{
            width: "60px",
            height: "20px",
            backgroundColor: `var(${color})`,
            borderRadius: "10px",
          }}
        ></div>
        <span className="ml-5 mt-auto mb-auto">{title}</span>
      </div>
    );
  };

  render() {
    let folders = this.props.folders.filter((folder) => {
      if (this.props.type && parseInt(this.props.type) !== folder.typeId) {
        return false;
      }
      if (
        this.props.company &&
        parseInt(this.props.company) !== folder.companyId
      ) {
        return false;
      }
      if (this.props.state && parseInt(this.props.state) !== folder.stateId) {
        return false;
      }
      if (this.props.search) {
        const licensePlate = (folder.licensePlate || "")
          .replace(/ /g, "")
          .replace(/-/g, "")
          .toUpperCase();
        const reference = (folder.reference || "").toUpperCase();

        if (
          !licensePlate.includes(this.props.search) &&
          !reference.includes(this.props.search)
        ) {
          return false;
        }
      }
      if (this.props.client) {
        if (
          !(folder.nameClient || "").toUpperCase().includes(this.props.client)
        ) {
          return false;
        }
      }
      if (this.props.expert) {
        if (this.props.expert !== folder.expertInitial) {
          return false;
        }
      }

      if (this.props.agency && Number(this.props.agency) !== folder.agencyId) {
        return false;
      }

      if (this.props.region && Number(this.props.region) !== folder.regionId) {
        return false;
      }

      if (
        this.context.isLockFolders &&
        folder.lockUser &&
        folder.lockUser !== this.context.id
      ) {
        return false;
      }

      return true;
    });

    return (
      <Card
        className="mt-10 mt-20-xl flex-column"
        classNameChildren="overflow-x-auto"
      >
        <Table
          states={[1, 2, 3, 4, 5, 6, 7, 8, 9]}
          folders={folders}
          getFolders={this.props.getFolders}
        />
        <div className="mt-10" />
        {this.getColorLegend(
          "Verrouillé par un autre utilisateur",
          "--red-light"
        )}
        {this.getColorLegend("Sauvegardé localement", "--green-light")}
        {this.getColorLegend(
          "Modification serveur attente mise à jour locale",
          "--purple-light"
        )}
        {this.getColorLegend(
          "Modification locale attente mise à jour serveur",
          "--blue-light"
        )}
      </Card>
    );
  }
}
