import React from "react";
import { Row, Col } from "react-flexbox-grid";
import { FormTextarea, Switch, FormCheckbox } from "ui-kit-ck-consultant";

import CustomCard from "../../general/CustomCard";

import AuthContext from "../../../../context/AuthContext";

export default class PositionKnowParties extends React.Component {
  constructor(props) {
    super(props);
    this.displayName = "PositionKnowParties";
  }

  static contextType = AuthContext;

  setData = (tmpData) => {
    if (this.context.updateDataExpertise) {
      this.context.updateDataExpertise("positionKnowParties", tmpData);
    } else {
      this.context.updateDataReport("positionKnowParties", tmpData);
    }
  };

  getData = () => {
    return (
      <>
        <Row>
          {Object.keys(this.context.parties).map((key) => {
            if (this.props.dataAttendees[key] === 1) {
              return (
                <Col key={key} xs={12} md={6}>

                  {!this.props.data.hasOwnProperty("parties") ||
                  (!this.props.data["parties"].hasOwnProperty(key) ||
                    (this.props.data["parties"].hasOwnProperty(key) &&
                      !this.props.data["parties"][key].hide)) ? (
                    <Switch
                      className="mb-10"
                      title={`${this.context.parties[key].kind} ${
                        this.context.parties[key].name
                      } - ${
                        this.context.parties[key].quality === "Autre"
                          ? this.context.parties[key].otherQuality
                          : this.context.parties[key].quality
                      }`}
                      value1="Non"
                      value2="Oui"
                      color1="#f2711c"
                      color2="#026fc2"
                      idxActive={
                        this.props.data.hasOwnProperty("parties") &&
                        this.props.data["parties"].hasOwnProperty(key)
                          ? this.props.data["parties"][key].switch
                          : 0
                      }
                      onChange={(type) => {
                        let tmpData = this.props.data;

                        if (!tmpData.hasOwnProperty("parties")) {
                          tmpData.parties = {};
                        }
                        if (!tmpData["parties"].hasOwnProperty(key)) {
                          tmpData.parties[key] = {};
                        }
                        tmpData["parties"][key].switch = type;

                        this.setData(tmpData);
                      }}
                    />
                  ) : null}
                  <div className="m-10">
                    <FormCheckbox
                      text="Masquer"
                      className="mb-20 mt-auto"
                      checked={
                        this.props.data.hasOwnProperty("parties") &&
                        this.props.data["parties"].hasOwnProperty(key)
                          ? this.props.data["parties"][key].hide
                          : false
                      }
                      onChange={(e) => {
                        const { checked } = e.target;
                        let tmpData = this.props.data;

                        if (!tmpData.hasOwnProperty("parties")) {
                          tmpData.parties = {};
                        }
                        if (!tmpData["parties"].hasOwnProperty(key)) {
                          tmpData.parties[key] = {};
                        }
                        tmpData["parties"][key].hide = checked;

                        this.setData(tmpData);
                      }}
                    />
                  </div>

                  {this.props.data.hasOwnProperty("parties") &&
                  this.props.data["parties"].hasOwnProperty(key) &&
                  this.props.data["parties"][key].switch === 1 ? (
                    <FormTextarea
                      title="Position"
                      value={
                        this.props.data.hasOwnProperty("parties") &&
                        this.props.data["parties"].hasOwnProperty(key)
                          ? this.props.data["parties"][key].value
                          : ""
                      }
                      onChange={({ target }) => {
                        let tmpData = this.props.data;
                        if (!tmpData.hasOwnProperty("parties")) {
                          tmpData.parties = {};
                        }
                        if (!tmpData["parties"].hasOwnProperty(key)) {
                          tmpData.parties[key] = {};
                        }
                        tmpData["parties"][key].value = target.value;
                        this.setData(tmpData);
                      }}
                    />
                  ) : null}
                </Col>
              );
            } else {
              return null;
            }
          })}
        </Row>
        <FormTextarea
          title="Observations"
          value={this.props.data.comment}
          onChange={({ target }) => {
            let tmpData = this.props.data;
            tmpData.comment = target.value;
            this.setData(tmpData);
          }}
        />
      </>
    );
  };

  render() {
    return (
      <>
        {this.context.isReport ? (
          this.getData()
        ) : (
          <CustomCard
            title="Position connue des parties"
            name={this.displayName}
          >
            {this.getData()}
          </CustomCard>
        )}
      </>
    );
  }
}
