import React, { Fragment } from "react";
import { Col, Row } from "react-flexbox-grid";
import {
  Card,
  FormInput,
  FormSelect,
  Loader,
  FormSelectMultiples,
  ButtonIcon,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

import ModalPlaceOfExpertise from "../../../../components/folder/general/ModalPlaceOfExpertise";
import HistoryTable from "../../../../components/folder/file-preparation/HistoryTable";
import BottomButton from "../../../../components/folder/file-preparation/BottomButton";

import Lexical from "../../../../lexical/Lexical";

import { generateString, getParty } from "../../../../utils/general";

import AuthContext from "../../../../context/AuthContext";
import HistoryPostalTable from "../../../../components/folder/file-preparation/HistoryPostalTable";

export default class Template extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      isModal: false,
      recipient: "",
      recipients: [],
      emailRecipients: "",
      object: "",
      editorState: "",
      files: [],
      dateAppointment: "",
      hourAppointment: "",
      placeOfExpertise: {
        name: "",
        address: "",
        zipCode: "",
        city: "",
        phone: "",
      },
    };
  }

  static contextType = AuthContext;

  componentDidMount() {
    if (this.props.tmpData) {
      let dataState = this.state;

      Object.keys(this.state).forEach((key) => {
        const data = this.props.tmpData[key];
        if (
          data &&
          (key !== "recipient" || this.context.parties.hasOwnProperty(data))
        ) {
          dataState[key] = data;
        }
      });

      this.setState(dataState);
    }

    if (this.props.isExpertise) {
      const garage = getParty(this.context.parties, "Réparateur", "Lésé");
      const depositary = getParty(this.context.parties, "Dépositaire", "Lésé");

      let user = {};
      if (
        depositary &&
        depositary.hasOwnProperty("id") &&
        depositary.party === "Lésé"
      ) {
        user = depositary;
      } else if (
        garage &&
        garage.hasOwnProperty("id") &&
        garage.party === "Lésé"
      ) {
        user = garage;
      } else if (depositary && depositary.hasOwnProperty("id")) {
        user = depositary;
      } else if (garage && garage.hasOwnProperty("id")) {
        user = garage;
      }

      this.setState(
        {
          dateAppointment: this.context.dateAppointment
            ? moment(this.context.dateAppointment).format("YYYY-MM-DD")
            : "",
          hourAppointment: this.context.hourAppointment
            ? this.context.hourAppointment
            : "",
          placeOfExpertise: {
            name: user.name,
            address: user.address,
            zipCode: user.zipCode,
            city: user.city,
            phone: user.phone,
          },
        },
        () => {
          this.getData();
        }
      );
    } else {
      this.getData();
    }
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
      this.getData();
    }
  }

  getData = () => {
    let tmpState = this.state;

    Object.keys(this.state).forEach((key) => {
      const keySplit = key.split("_");
      if (
        this.props.data.hasOwnProperty(keySplit[0]) &&
        this.props.data[keySplit[0]].hasOwnProperty(keySplit[1])
      ) {
        tmpState[key] = this.props.data[keySplit[0]][keySplit[1]];
      }
    });

    this.setState(tmpState);
  };

  handleInputChange = (e, callback = () => {}) => {
    const { value, name } = e.target;

    this.setState(
      {
        [name]: value,
      },
      () => {
        if (name === "recipient" && !this.props.disableTemplate) {
          this.props.getTemplateMail(
            this.props.name,
            value !== "Autre" ? this.context.parties[value].quality : "",
            {
              ...this.context.getDataTemplate(),
              dateAppointment: moment(this.state.dateAppointment).format(
                "DD/MM/YYYY"
              ),
              hourAppointment: this.state.hourAppointment,
              placeOfExpertiseName: this.state.placeOfExpertise.name,
              placeOfExpertiseAddress: this.state.placeOfExpertise.address,
              placeOfExpertiseZipCode: this.state.placeOfExpertise.zipCode,
              placeOfExpertiseCity: this.state.placeOfExpertise.city,
              placeOfExpertisePhone: this.state.placeOfExpertise.phone,
            },
            (result) => {
              if (result.success) {
                this.setState(
                  {
                    editorState: result.data,
                  },
                  () => {
                    this.updateTemplate(callback);
                  }
                );
              } else {
                window.alert("Une erreur est survenue");
              }
            }
          );
        } else {
          this.updateTemplate(callback);
        }
      }
    );
  };

  updateTemplate = (callback = () => {}) => {
    let tmpData = this.props.tmpData;

    tmpData = {
      recipient: this.state.recipient,
      recipients: this.state.recipients,
      emailRecipients: this.state.emailRecipients,
      object: this.state.object,
      editorState: this.state.editorState,
      files: this.state.files,
      dateAppointment: this.state.dateAppointment,
      hourAppointment: this.state.hourAppointment,
      placeOfExpertise: this.state.placeOfExpertise,
    };

    this.setData(tmpData, callback);
  };

  setData = (data, callback = () => {}, type = null, keyData = null) => {
    this.props.setData(`tmp_${this.props.name}`, data, callback, type, keyData);
  };

  saveMessage = () => {
    this.setState({ isLoad: true }, () => {
      const name = `${this.context.folderId}-${this.props.name}`;

      let data = JSON.parse(localStorage.getItem(name) || "[]");

      data.push({
        date: moment().format("YYYY-MM-DD HH:mm:ss"),
        recipient: this.state.recipient,
        recipients: this.state.recipients,
        emailRecipients: this.state.emailRecipients,
        object: this.state.object,
        editorState: this.state.editorState,
      });

      localStorage.setItem(name, JSON.stringify(data));
      this.setState({ isLoad: false });
    });
  };

  addMessage = (isPreview = false, callback = () => {}) => {
    const data = this.context.getDataTemplate();
    const token = generateString();

    this.props.addMessage(
      this.state.recipient,
      this.state.recipients,
      this.state.emailRecipients,
      this.state.object,
      this.state.editorState,
      this.props.message.replace(
        "[replace]",
        `${data.expertKind} ${data.expertName}`
      ),
      this.state.files,
      this.props.name,
      3,
      token,
      isPreview,
      (result) => {
        if (result.success) {
          if (!isPreview) {
            this.setState(
              {
                recipient: "",
                recipients: [],
                emailRecipients: "",
                object: "",
                editorState: "",
                files: [],
              },
              () => {
                this.setData({}, callback);
              }
            );
          } else {
            callback(result);
          }
        }
      }
    );
  };

  addMessagePostal = (isPreview = false, callback = () => {}) => {
    const data = this.context.getDataTemplate();
    const token = generateString();

    this.props.addMessagePostal(
      this.state.recipient,
      this.state.recipients,
      this.state.object,
      this.state.editorState,
      this.props.message.replace(
        "[replace]",
        `${data.expertKind} ${data.expertName}`
      ),
      this.state.files,
      this.props.name,
      this.props.name,
      token,
      isPreview,
      (result) => {
        if (result.success) {
          if (!isPreview) {
            this.setState(
              {
                recipient: "",
                recipients: [],
                emailRecipients: "",
                object: "",
                editorState: "",
                files: [],
              },
              () => {
                this.setData({}, callback);
              }
            );
          } else {
            callback(result);
          }
        }
      }
    );
  };

  getPartyWithId = (key) => {
    if (this.context.parties.hasOwnProperty(key)) {
      return this.context.parties[key];
    } else {
      return {};
    }
  };

  getTo = (recipient, isOther = false, isEmail = false) => {
    const data = this.getPartyWithId(recipient);

    if (isEmail) {
      return <>&nbsp;/&nbsp;{recipient}</>;
    } else if (isOther) {
      return (
        <>
          &nbsp;/&nbsp;{data.kind} {data.name}
        </>
      );
    } else {
      return (
        <>
          {data.kind} {data.name}
        </>
      );
    }
  };

  render() {
    const { placeOfExpertise } = this.state;

    return (
      <>
        <Card className="mt-10 mt-20-xl" title={this.props.title}>
          <Row>
            <Col xs={12} md={4} lg={3}>
              {this.props.isExpertise ? (
                <>
                  <FormInput
                    className="mb-10"
                    type="date"
                    name="dateAppointment"
                    value={this.state.dateAppointment}
                    title="Date de l'expertise"
                    onChange={this.handleInputChange}
                    required
                  />
                  <FormInput
                    className="mb-10"
                    type="time"
                    name="hourAppointment"
                    value={this.state.hourAppointment}
                    title="Heure de l'expertise"
                    onChange={this.handleInputChange}
                    required
                  />
                  <span style={{ fontSize: "14px" }}>
                    <b>Lieu d'expertise</b>
                  </span>
                  <div
                    className="bg-gray-light p-10 mt-10 mb-10"
                    style={{ borderRadius: "6px" }}
                    title="Lieu d'expertise"
                  >
                    <Row end="xs">
                      <div className="d-flex mr-10">
                        <ButtonIcon
                          className="ml-auto p-0"
                          small
                          onClick={() => {
                            this.setState({ isModal: true });
                          }}
                        >
                          <FontAwesomeIcon className="black" icon={faPen} />
                        </ButtonIcon>
                      </div>
                    </Row>
                    <p className="m-0 mb-5">
                      <b>{placeOfExpertise.name}</b>
                    </p>
                    <p className="m-0 mb-5">{placeOfExpertise.address}</p>
                    <p className="m-0 mb-5">{placeOfExpertise.zipCode}</p>
                    <p className="m-0 mb-5">{placeOfExpertise.city}</p>
                  </div>{" "}
                </>
              ) : null}
              <FormSelect
                disabled={
                  !this.props.isExpertise
                    ? false
                    : !this.state.dateAppointment || !this.state.hourAppointment
                }
                className="mb-10"
                name="recipient"
                value={this.state.recipient}
                title="Destinataire principal"
                ignore={true}
                init="Veuillez sélectionner un destinataire"
                options={[
                  ...this.context.partiesOptions.filter((element) => {
                    if (!this.props.parties.length) {
                      return true;
                    }

                    if (
                      this.props.parties.filter(
                        (party) =>
                          (!party.quality ||
                            party.quality === element.quality) &&
                          (!party.party || party.party === element.party)
                      ).length
                    ) {
                      return true;
                    } else {
                      return false;
                    }
                  }),
                ]}
                onChange={(e) =>
                  this.handleInputChange(e, () => {
                    const object = `${this.props.title} - N°${
                      this.context.dataAdministrative
                        .dataAdministrativeInformation.mission.referenceSinister
                    } - N°${
                      this.context.dataAdministrative
                        .dataAdministrativeInformation.mission.reference
                    } - ${getParty(this.context.parties, "Lésé").name} - ${
                      this.context.dataAdministrative
                        .dataVehicleIdentification[0].brand
                    } - ${
                      this.context.dataAdministrative
                        .dataVehicleIdentification[0].licensePlate
                    }`;

                    this.setState({
                      object,
                    });
                  })
                }
              />
              <FormSelectMultiples
                className="mb-10"
                title="Autre(s) destinataire(s)"
                value={this.state.recipients}
                default="Autre(s) destinataire(s)"
                options={this.context.partiesOptions.filter(
                  (element) => this.context.parties[element.value].email
                )}
                onChange={(value) =>
                  this.setState(
                    {
                      recipients: value,
                    },
                    () => this.updateTemplate()
                  )
                }
              />
              <FormInput
                className="mb-10"
                name="emailRecipients"
                value={this.state.emailRecipients}
                title="Saisie libre adresse mail (;)"
                onChange={this.handleInputChange}
              />
            </Col>

            <Col xs={12} md={8} lg={9}>
              <FormInput
                className="mb-10"
                name="object"
                value={this.state.object}
                title="Objet"
                onChange={(e) => this.handleInputChange(e)}
              />
              <Lexical
                value={this.state.editorState}
                onChange={(editorState) =>
                  this.setState({ editorState }, () => this.updateTemplate())
                }
              />
              <BottomButton
                onFilesChange={(files) => {
                  this.setState({ files });
                }}
                files={this.state.files}
                saveMessage={this.saveMessage}
                addMessage={this.addMessage}
                addMessagePostal={this.addMessagePostal}
                recipient={this.state.recipient}
                canBeSendByLetter={!this.state.emailRecipients.length}
              />
            </Col>
          </Row>
        </Card>
        <Card title="Sauvegarde(s)" className="mt-10 mt-20-xl">
          <Table>
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th>Date</Th>
                <Th>Destinataire(s)</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {JSON.parse(
                localStorage.getItem(
                  `${this.context.folderId}-${this.props.name}`
                ) || "[]"
              ).map((element, idx) => (
                <Tr key={idx}>
                  <Th>{idx + 1}</Th>
                  <Td>{moment(element.date).format("DD/MM/YYYY HH:mm:ss")}</Td>
                  <Td>
                    {this.getTo(element.recipient)}
                    {element.recipients
                      ? element.recipients.map((recipient) => (
                          <Fragment key={recipient}>
                            {this.getTo(recipient, true)}
                          </Fragment>
                        ))
                      : null}{" "}
                    / {element.emailRecipients}
                  </Td>
                  <Td>
                    <ButtonIcon
                      className="red"
                      small
                      onClick={() => {
                        this.setState(
                          {
                            isLoad: true,
                          },
                          () => {
                            let data = JSON.parse(
                              localStorage.getItem(
                                `${this.context.folderId}-${this.props.name}`
                              ) || "[]"
                            );

                            data.splice(idx, 1);

                            localStorage.setItem(
                              `${this.context.folderId}-${this.props.name}`,
                              JSON.stringify(data)
                            );

                            this.setState({
                              isLoad: false,
                            });
                          }
                        );
                      }}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </ButtonIcon>
                    <ButtonIcon
                      className="blue"
                      small
                      onClick={() =>
                        this.setState({
                          recipient: element.recipient,
                          recipients: element.recipients,
                          emailRecipients: element.emailRecipients,
                          object: element.object,
                          editorState: element.editorState,
                        })
                      }
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </ButtonIcon>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Card>
        <HistoryTable name={this.props.historyTitle} data={this.props.data} />
        <HistoryPostalTable
          mailevaData={this.context.mailevaData.filter(
            (el) =>
              el.type === this.props.name &&
              this.context.folderId === el.folderId
          )}
        />
        <input
          style={{ display: "none" }}
          ref={(ref) => (this.refInput = ref)}
          type="file"
          onChange={this.handleInputFileChange}
        />
        <ModalPlaceOfExpertise
          show={this.state.isModal}
          data={this.state.placeOfExpertise}
          updateData={(data, callback) => {
            this.setState(
              {
                placeOfExpertise: {
                  name: data.name,
                  address: data.address,
                  zipCode: data.zipCode,
                  city: data.city,
                  phone: data.phone,
                },
              },
              callback
            );
          }}
          onClose={() => this.setState({ isModal: false })}
        />
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
