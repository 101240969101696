import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  FormInput,
  FormSelect,
  ButtonIcon,
} from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";

import CustomCard from "../../general/CustomCard";

import AuthContext from "../../../../context/AuthContext";

export default class Attachments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModal: false,
    };
    this.displayName = "Attachments";
  }

  static contextType = AuthContext;

  handleAddLineClick = () => {
    let tmpData = this.props.data;

    tmpData.push({
      text: "",
      file: "DEFAULT",
    });

    this.setData(tmpData);
  };

  setData = (tmpData) => {
    if (this.context.updateDataExpertise) {
      this.context.updateDataExpertise("attachments", tmpData);
    } else {
      this.context.updateDataReport("attachments", tmpData);
    }
  };

  render() {
    return (
      <CustomCard title="Pièces annexes" name={this.displayName}>
        <Table>
          <Thead>
            <Tr>
              <Th>Nom</Th>
              <Th>Pièce jointe</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {this.props.data.map((element, idx) => (
              <Tr key={idx}>
                <Td>
                  <FormInput
                    styleInput={{
                      border: "none",
                      padding: "0 10px",
                      fontSize: "13px",
                      height: "36px",
                    }}
                    value={element.text}
                    onChange={({ target }) => {
                      let tmpData = this.props.data;
                      tmpData[idx].text = target.value;
                      this.setData(tmpData);
                    }}
                  />
                </Td>
                <Td>
                  <FormSelect
                    styleInput={{
                      border: "none",
                      padding: "0 10px",
                      fontSize: "13px",
                      height: "36px",
                    }}
                    name="quality"
                    value={element.file}
                    ignore={true}
                    options={[
                      { value: "DEFAULT", text: "Aucune" },
                      ...this.context.files.map((file) => ({
                        value: file.token.toString(),
                        text: file.filename,
                      })),
                    ]}
                    onChange={({ target }) => {
                      let tmpData = this.props.data;
                      tmpData[idx].file = target.value;
                      this.setData(tmpData);
                    }}
                    required
                  />
                </Td>
                <Td className="text-center">
                  <ButtonIcon
                    small
                    className="red"
                    onClick={() => {
                      window.confirmCustom(
                        "Êtes-vous sûr de vouloir supprimer cette ligne ?",
                        (result) => {
                          if (result) {
                            let tmpData = this.props.data;
                            tmpData.splice(idx, 1);
                            this.setData(tmpData);
                          }
                        }
                      );
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </ButtonIcon>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <div className="d-flex">
          <ButtonIcon
            small
            isBackground
            className="mt-10 ml-auto"
            onClick={this.handleAddLineClick}
          >
            <FontAwesomeIcon icon={faPlus} />
          </ButtonIcon>
        </div>
      </CustomCard>
    );
  }
}
