import React from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import routes from "./routes";

import { refreshToken, getOptions } from "./requests/general";
import { getAll } from "./requests/maileva";

import Alert from "./components/Alert";

import AuthContext from "./context/AuthContext";

import ReactGA from "react-ga4";
import { Loader } from "ui-kit-ck-consultant";
ReactGA.initialize("G-NZLLDYEGR5");

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isReady: false,
      connected: null,
      name: null,
      agencyId: null,
      regionId: null,
      id: null,
      url: null,
      types: [],
      states: [],
      companies: [],
      agencies: [],
      regions: [],
      appointmentTypes: [],
      users: [],
      signature: "",
    };
  }

  componentDidMount() {
    this.checkAuth();

    this.onMessage = (result) => {
      if (result.data.type === "auth" && result.data.success) {
        window.localStorage.setItem("token", result.data.token);
        this.checkAuth();
      }
    };

    window.addEventListener("message", this.onMessage, false);

    this.intervalToken = setInterval(() => {
      this.checkAuth();
    }, 30 * 60 * 1000);
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.onMessage);
    clearInterval(this.intervalToken);
  }

  checkAuth = () => {
    const token = window.localStorage.getItem("token") || "";

    if (navigator.onLine) {
      var url = window.location.href;
      var arr = url.split("/");

      refreshToken(token, arr[0] + "//" + arr[2], (result) => {
        if (result.success) {
          if (result.redirect) {
            this.setState({
              redirect: result.redirect.replace("signin", "signin-light"),
            });
          } else {
            if (document.location.hash.includes("success")) {
              document.location.hash = "/";
            } else {
              const oldState = this.state;
              const newState = {
                isReady: true,
                connected: true,
                name: result.data.name,
                agencyId: result.data.agency_id,
                regionId: result.data.region_id,
                id: result.data.id,
                url: result.url,
                types: oldState.types,
                states: oldState.states,
                companies: oldState.companies,
                agencies: oldState.agencies,
                regions: oldState.regions,
                appointmentTypes: oldState.appointmentTypes,
                users: oldState.users,
                signature: result.data.signature,
                redirect: "",
              };
              if (this.state.isReady) {
                if (JSON.stringify(oldState) !== JSON.stringify(newState)) {
                  this.setState(newState);
                }
              } else {
                if (JSON.stringify(oldState) !== JSON.stringify(newState)) {
                  this.getOptions((result) => {
                    newState.types = result.data.types;
                    newState.states = result.data.states;
                    newState.companies = result.data.companies;
                    newState.agencies = result.data.agencies;
                    newState.regions = result.data.regions;
                    newState.appointmentTypes = result.data.appointmentTypes;
                    newState.users = result.data.users;

                    this.setState(newState, () => {
                      getAll((result) => {
                        if (result.success) {
                          this.setState({ mailevaData: result.data });
                        } else {
                          this.setState({ mailevaData: [] });
                        }
                      });
                    });
                  });
                }
              }
            }
          }
        } else {
          this.setState({ connected: false }, () => {
            setTimeout(() => {
              if (result.redirect) {
                this.setState({
                  redirect: result.redirect.replace("signin", "signin-light"),
                });
              }
            }, 1000);
          });
        }
      });
    } else {
      this.setState({ isReady: token ? true : false }, () =>
        this.getOptions(this.state)
      );
    }
  };

  getOptions = (callback) => {
    getOptions((result) => {
      callback(result);
    });
  };

  render() {
    if (this.state.redirect) {
      return (
        <div className="d-flex">
          <iframe
            title="auth"
            src={this.state.redirect}
            style={{
              width: "100%",
              height: "100%",
              overflow: "hidden",
              border: 0,
            }}
          />
        </div>
      );
    } else if (!this.state.isReady) {
      return <Loader show={true} />;
    }

    return (
      <AuthContext.Provider
        value={{
          name: this.state.name,
          agencyId: this.state.agencyId,
          regionId: this.state.regionId,
          id: this.state.id,
          url: this.state.url,
          types: this.state.types,
          states: this.state.states,
          companies: this.state.companies,
          regions: this.state.regions,
          agencies: this.state.agencies,
          appointmentTypes: this.state.appointmentTypes,
          users: this.state.users,
          signature: this.state.signature,
          domain:
            process.env.NODE_ENV === "production"
              ? window.location.origin.includes("development")
                ? "https://development.redaxion.fr"
                : "https://redaxion.fr"
              : "http://localhost:5012",
          isSoftware: !!(
            (window.process && window.process.type) ||
            window.electronAPI
          ),
          mailevaData: this.state.mailevaData,
          updateMailevaData: (data, callback) => {
            this.setState(
              {
                mailevaData: [...data, ...this.state.mailevaData],
              },
              callback
            );
          },
        }}
      >
        <HashRouter basename={process.env.REACT_APP_BASENAME || ""}>
          <Switch>
            {routes.map((route, index) => {
              return (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  render={(props) => {
                    return (
                      <route.layout {...props}>
                        <route.component {...props} />
                      </route.layout>
                    );
                  }}
                />
              );
            })}
          </Switch>
        </HashRouter>
        <Alert />
      </AuthContext.Provider>
    );
  }
}
