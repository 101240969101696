import React from "react";

import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faFilePdf,
  faUnlock,
  faLock,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

import { ButtonIcon, Button, Loader } from "ui-kit-ck-consultant";

// ADMINISTRATIVE componennts
import VehicleIdentification from "../../../views/dashboard/folder/administrative/VehicleIdentification";

// EXPERTISE components
import Informations from "./expertise/Informations";
import Attendees from "./expertise/Attendees";
import DeclarationParties from "./expertise/DeclarationParties";
import ExpertiseConditions from "./expertise/ExpertiseConditions";
import Vehicle from "./expertise/Vehicle";
import Findings from "./expertise/Findings";
import Fees from "./expertise/Fees";
import FolderOrientation from "./expertise/FolderOrientation";
import PositionKnowParties from "./expertise/PositionKnowParties";
import Signatures from "./expertise/Signatures";
import End from "./expertise/End";
import Attachments from "./expertise/Attachments";

// REPORT components
import RCdamageAssessment from "./report/RCdamageAssessment";
import DamageAssessment from "./report/DamageAssessment";

import HistoryTable from "../file-preparation/HistoryTable";
import RecallFacts from "../../../views/dashboard/folder/RecallFacts";

import ModalSendPdf from "../general/ModalSendPdf";

import { getData, getParties, getPartiesOptions } from "../../../utils/general";

import { documentToPdfRequest } from "../../../requests/mail";
import { getFolderDocument } from "../../../requests/folder";

import AuthContext from "../../../context/AuthContext";

export default class Expertise extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      isModalMail: false,
      mailBase64: "",
      mailFiles: [],
      dataFolderDocument: [],
    };
  }
  static contextType = AuthContext;

  componentDidMount() {
    this.getFolderDocument();
  }

  componentDidUpdate(prevProps) {
    if (this.props.data.token !== prevProps.data.token) {
      this.getFolderDocument();
    }
  }

  getFolderDocument = () => {
    this.setState(
      {
        isLoad: true,
      },
      () => {
        getFolderDocument(
          {
            folderId: this.context.folderId,
            token: this.props.data.token,
            type: this.props.type,
          },
          (result) => {
            if (result.success) {
              this.setState({
                isLoad: false,
                dataFolderDocument: result.data,
              });
            } else {
              this.setState({
                isLoad: false,
              });
            }
          }
        );
      }
    );
  };

  getStatusCard = (name) => {
    const statusCard = getData(this.props.data, "statusCard")[name];
    return statusCard === undefined ? true : statusCard;
  };

  updateStatusCard = (name) => {
    let tmpData = this.props.data;

    tmpData.statusCard[name] =
      tmpData.statusCard[name] !== undefined
        ? !tmpData.statusCard[name]
        : false;

    this.props.updateExpertise(tmpData);
  };

  getJustifyCard = (name) => {
    const justifyCard = getData(this.props.data, "justifyCard")[name];
    return justifyCard === undefined ? false : justifyCard;
  };

  updateJustifyCard = (name) => {
    let tmpData = this.props.data;

    tmpData.justifyCard = tmpData.justifyCard || {};

    tmpData.justifyCard[name] =
      tmpData.justifyCard[name] !== undefined
        ? !tmpData.justifyCard[name]
        : true;

    this.props.updateExpertise(tmpData);
  };

  getJumpCard = (name) => {
    const jumpCard = getData(this.props.data, "jumpCard")[name];
    return !!jumpCard;
  };

  updateJumpCard = (name) => {
    let tmpData = this.props.data;

    if (!tmpData.jumpCard) {
      tmpData.jumpCard = {};
    }

    tmpData.jumpCard[name] =
      tmpData.jumpCard[name] !== undefined ? !tmpData.jumpCard[name] : false;

    this.props.updateExpertise(tmpData);
  };

  updateDataExpertise = (name, data, callback = () => {}) => {
    let tmpData = this.props.data;

    if (
      tmpData["signaturesEnd"] &&
      Object.keys(tmpData["signaturesEnd"]).length &&
      name !== "signaturesEnd" &&
      name !== "informations" &&
      name !== "retrieveSignature"
    ) {
      window.confirmCustom(
        "Attention, vous allez perdre les signatures des constatations. Êtes-vous sûr de vouloir effectuer cette modification ?",
        (result) => {
          if (result) {
            tmpData[name] = data;
            tmpData["signaturesEndSave"] = tmpData["signaturesEnd"];
            tmpData["signaturesEnd"] = {};
            this.props.updateExpertise(tmpData, callback);
          }
        }
      );
    } else {
      tmpData[name] = data;
      this.props.updateExpertise(tmpData, callback);
    }

    if (["RCdamageAssessment", "damageAssessment"].includes(name)) {
      let tmpDataEvaluation = this.props.dataEvaluation;
      tmpDataEvaluation[name] = data;

      this.context.updateDataFolder("dataEvaluation", tmpDataEvaluation);
    }
  };

  downloadPDF = (data) => {
    const reference =
      this.context.dataAdministrative.dataAdministrativeInformation.mission
        .reference;
    const expertiseType =
      this.props.data.informations && this.props.data.informations.type
        ? this.props.data.informations.type
        : "";
    this.documentToPdf(data, (base64) => {
      const name = `PV-d-expertise-${expertiseType
        .replace(/é/g, "e")
        .toLowerCase()}-${reference}-du-${moment().format(
        "YYYY-MM-DD_HH-mm-ss"
      )}.pdf`;

      this.context.handleOpenModalPDF(base64, name);
    });
  };

  documentToPdf = (data, callback, isWebservice = false) => {
    data = {
      ...data,
      folderId: this.context.folderId,
      folderType: this.context.shortType,
      isWebservice,
      expert:
        this.context.users.filter((expert) => {
          return (
            expert.id ===
            parseInt(
              this.context.dataAdministrative.dataAdministrativeInformation
                .mission.expert
            )
          );
        })[0] || {},
    };

    this.setState(
      {
        isLoad: true,
      },
      () => {
        documentToPdfRequest(data, (result) => {
          if (result.success) {
            this.setState(
              {
                isLoad: false,
              },
              () => {
                callback(result.data, result.files);
              }
            );
          } else {
            this.setState(
              {
                isLoad: false,
              },
              () => {
                window.alert("Une erreur est survenue");
              }
            );
          }
        });
      }
    );
  };

  updateRetrieveSignature = (newData) => {
    let tmpData = this.props.data;
    if (tmpData.hasOwnProperty("retrieveSignature")) {
      const index = tmpData.retrieveSignature.findIndex(
        (el) => el.key === newData.key
      );

      if (index !== -1) {
        tmpData.retrieveSignature[index] = {
          ...tmpData.retrieveSignature[index],
          value: newData.value,
        };
      } else {
        tmpData.retrieveSignature.push(newData);
      }
    } else {
      tmpData.retrieveSignature = [newData];
    }
    this.updateDataExpertise("retrieveSignature", tmpData.retrieveSignature);
  };

  render() {
    const { data, dataAdministrative, dataRecallFacts } = this.props;
    const parties = getParties(this.state.dataAdministrative);
    const partiesOptions = getPartiesOptions(this.state.dataAdministrative);

    return (
      <AuthContext.Provider
        value={{
          ...this.context,
          updateStatusCard: this.updateStatusCard,
          getStatusCard: this.getStatusCard,
          updateJustifyCard: this.updateJustifyCard,
          getJustifyCard: this.getJustifyCard,
          updateJumpCard: this.updateJumpCard,
          getJumpCard: this.getJumpCard,
          isLock: this.context.isLock || data.isLock,
          isExpertise: true,
          updateDataExpertise: this.updateDataExpertise,
          data,
        }}
      >
        <Informations data={getData(data, "informations")} />
        <Attendees
          data={getData(data, "attendees")}
          dataAdministrative={getData(
            dataAdministrative,
            "dataIdentificationParties"
          )}
          updateDataAdministrative={(name, data) => {
            let tmpData = this.props.dataAdministrative;
            tmpData[name] = data;
            this.context.updateDataFolder(
              "dataAdministrative",
              tmpData,
              () => {}
            );
          }}
          onAddLineClick={(key) =>
            this.refDeclarationParties
              ? this.refDeclarationParties.onAddLineClick(key)
              : () => {}
          }
          onRemoveLineClick={(key) =>
            this.refDeclarationParties
              ? this.refDeclarationParties.onRemoveLineClick(key)
              : () => {}
          }
        />
        <Signatures
          title="Signatures des personnes présentes"
          data={getData(data, "signaturesStart")}
          extra="Start"
          dataAttendees={getData(data, "attendees")}
        />
        <RecallFacts
          dataRecallFacts={dataRecallFacts}
          dataExpertiseConditions={getData(data, "expertiseConditions")}
          date={data.informations.date}
          data={getData(data, "recallFacts")}
        />
        <DeclarationParties
          ref={(ref) => {
            this.refDeclarationParties = ref;
          }}
          data={getData(data, "declarationParties", "array")}
          partiesOptions={this.context.partiesOptions.filter(
            (element) => getData(data, "attendees")[element.value] === 1
          )}
        />
        <ExpertiseConditions data={getData(data, "expertiseConditions")} />
        <VehicleIdentification data={getData(data, "vehicleIdentification")} />
        <Vehicle
          dataExpertiseConditions={getData(data, "expertiseConditions")}
          data={getData(data, "vehicle")}
          statusCardLevelsWear={this.getStatusCard("LevelsWear")}
          updateStatusCardLevelsWear={() => this.updateStatusCard("LevelsWear")}
        />
        <Findings
          data={getData(data, "findings", "array")}
          dataAttendees={getData(data, "attendees")}
        />
        {this.context.dataAdministrative.dataAdministrativeInformation.mission
          .typeMission === "2" ? (
          <RCdamageAssessment
            data={getData(data, "RCdamageAssessment", "array")}
            dataEvaluation={this.props.dataEvaluation}
          />
        ) : (
          <DamageAssessment
            type={data.informations.type}
            data={getData(data, "damageAssessment", "array")}
            dataComment={getData(data, "damageAssessmentComment", "string")}
            dataEvaluation={this.props.dataEvaluation}
          />
        )}
        <Fees data={getData(data, "expertiseConditions")} />
        <FolderOrientation data={getData(data, "folderOrientation")} />
        <PositionKnowParties
          data={getData(data, "positionKnowParties")}
          dataAttendees={getData(data, "attendees")}
        />
        <Attachments data={getData(data, "attachments", "array")} />
        <End data={getData(data, "informations")} />
        <Signatures
          showCheckBox={true}
          data={getData(data, "signaturesEnd")}
          signaturesStart={getData(data, "signaturesStart")}
          extra="End"
          dataAttendees={getData(data, "attendees")}
          dataRetrieveSignature={getData(data, "retrieveSignature")}
          updateRetrieveSignature={this.updateRetrieveSignature}
        />
        {this.context.isLock ? null : (
          <div className="d-flex w-100 mt-20">
            <ButtonIcon
              isBackground
              className={"ml-auto bg-red"}
              disabled={this.context.isDisconnected}
              onClick={this.props.onClickDelete}
            >
              <FontAwesomeIcon icon={faTrash} />
            </ButtonIcon>
            <ButtonIcon
              isBackground
              className="white ml-10"
              disabled={this.context.isDisconnected}
              onClick={() =>
                this.downloadPDF({
                  administrative: dataAdministrative,
                  data: data,
                  recallFacts: dataRecallFacts,
                  type: "Expertise",
                })
              }
            >
              <FontAwesomeIcon icon={faFilePdf} />
            </ButtonIcon>
            <ButtonIcon
              isBackground
              className="white ml-10"
              disabled={this.context.isDisconnected}
              onClick={() => {
                this.documentToPdf(
                  {
                    administrative: dataAdministrative,
                    data: data,
                    recallFacts: dataRecallFacts,
                    type: "Expertise",
                  },
                  (base64, files) => {
                    this.setState({
                      isModalMail: true,
                      mailBase64: base64,
                      mailFiles: files,
                    });
                  }
                );
              }}
            >
              <FontAwesomeIcon icon={faEnvelope} />
            </ButtonIcon>
            <Button
              className="ml-10 w-auto"
              text={data.isLock ? "Réouvrir" : "Clôturer + Exporter"}
              disabled={this.context.isDisconnected}
              onClick={() => {
                window.confirmCustom(
                  data.isLock
                    ? "Êtes-vous sûr de vouloir réouvrir cette expertise ?"
                    : "Êtes-vous sûr de vouloir clôturer cette expertise ?",
                  (result) => {
                    if (result) {
                      let tmpData = this.props.data;
                      tmpData.isLock = !tmpData.isLock;

                      tmpData.isLock
                        ? this.props.updateExpertise(
                            tmpData,
                            () => {
                              this.context.updateFolderState(9);
                              this.documentToPdf(
                                {
                                  administrative: dataAdministrative,
                                  data: data,
                                  recallFacts: dataRecallFacts,
                                  type: "Expertise",
                                },
                                () => {},
                                true
                              );
                            },
                            12,
                            tmpData.token
                          )
                        : this.props.updateExpertise(
                            tmpData,
                            () => {},
                            13,
                            tmpData.token
                          );
                    }
                  }
                );
              }}
            >
              {data.isLock ? (
                <FontAwesomeIcon icon={faUnlock} />
              ) : (
                <FontAwesomeIcon icon={faLock} />
              )}
            </Button>
            {!data.isLock && (
              <Button
                className="ml-10 w-auto"
                text={data.isLock ? "Réouvrir" : "Clôturer"}
                disabled={this.context.isDisconnected}
                onClick={() => {
                  window.confirmCustom(
                    data.isLock
                      ? "Êtes-vous sûr de vouloir réouvrir cette expertise ?"
                      : "Êtes-vous sûr de vouloir clôturer cette expertise ?",
                    (result) => {
                      if (result) {
                        let tmpData = this.props.data;
                        tmpData.isLock = !tmpData.isLock;

                        tmpData.isLock
                          ? this.props.updateExpertise(
                              tmpData,
                              () => {
                                this.context.updateFolderState(9);
                                this.documentToPdf(
                                  {
                                    administrative: dataAdministrative,
                                    data: data,
                                    recallFacts: dataRecallFacts,
                                    type: "Expertise",
                                  },
                                  () => {},
                                  false
                                );
                              },
                              12,
                              tmpData.token
                            )
                          : this.props.updateExpertise(
                              tmpData,
                              () => {},
                              13,
                              tmpData.token
                            );
                      }
                    }
                  );
                }}
              >
                <FontAwesomeIcon icon={faLock} />
              </Button>
            )}
          </div>
        )}
        <ModalSendPdf
          token={data.token}
          dataAdministrative={dataAdministrative}
          informations={data.informations}
          data={this.state.mailBase64}
          files={this.state.mailFiles}
          show={this.state.isModalMail}
          type={"Expertise"}
          messageType={"le PV d'expertise"}
          partiesOption={partiesOptions}
          parties={parties}
          onClose={(isReload = false) =>
            this.setState(
              { isModalMail: false, mailBase64: "", mailFiles: [] },
              () => {
                if (isReload) {
                  this.getFolderDocument();
                }
              }
            )
          }
          date={this.props.data.informations.date}
          updateExpertise={this.props.updateExpertise}
          dataExpertise={this.props.data}
        />
        <Loader show={this.state.isLoad} />
        <HistoryTable data={this.state.dataFolderDocument} />
      </AuthContext.Provider>
    );
  }
}
