import React from "react";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FormInput,
  Table,
  Tbody,
  Tr,
  Td,
  ButtonIcon,
} from "ui-kit-ck-consultant";

import CustomCard from "../../general/CustomCard";

import AuthContext from "../../../../context/AuthContext";

export default class AdditionalDamage extends React.Component {
  constructor(props) {
    super(props);
    this.displayName = "AdditionalDamage";
  }
  static contextType = AuthContext;

  handleInputChange = (name, value, idx) => {
    let tmpData = this.props.data;
    tmpData[idx][name] = value;
    this.setData(tmpData);
  };

  onAddLineClick = () => {
    let tmpData = this.props.data;

    tmpData.push({});

    this.setData(tmpData);
  };

  setData = (tmpData) => {
    this.context.updateDataReport("additionalDamage", tmpData);
  };

  getData = () => {
    return (
      <>
        <Table>
          <Tbody>
            {this.props.data.map((element, idx) => (
              <Tr key={idx}>
                <Td className="p-10 w-100">
                  <FormInput
                    styleInput={{ border: "none" }}
                    placeholder="Motif"
                    value={element.value ? element.value : ""}
                    onChange={({ target }) => {
                      let tmpData = this.props.data;
                      tmpData[idx].value = target.value;
                      this.setData(tmpData);
                    }}
                  />
                </Td>
                <Td className="p-10">
                  <div className="d-flex">
                    <FormInput
                      className="w-100"
                      styleInput={{
                        border: "none",
                        minWidth: "180px",
                        textAlign: "right",
                      }}
                      placeholder="Montant"
                      type="number"
                      value={element.amount ? element.amount : ""}
                      onChange={({ target }) => {
                        let tmpData = this.props.data;
                        tmpData[idx].amount = target.value;
                        this.setData(tmpData);
                      }}
                    />
                    <span className="mt-auto mb-auto nowrap ml-10">
                      € HT /{" "}
                      {(element.amount ? element.amount * 1.2 : 0).toFixed(2)} €
                      TTC
                    </span>
                  </div>
                </Td>
                <Td className="p-10">
                  <ButtonIcon
                    className="red"
                    small
                    onClick={() => {
                      let tmpData = this.props.data;
                      tmpData.splice(idx, 1);
                      this.setData(tmpData);
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </ButtonIcon>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <div className="d-flex">
          <ButtonIcon
            small
            isBackground
            onClick={this.onAddLineClick}
            className="mt-10 ml-auto bg-blue"
          >
            <FontAwesomeIcon icon={faPlus} />
          </ButtonIcon>
        </div>
      </>
    );
  };

  render() {
    return (
      <CustomCard title="Frais annexes" name={this.displayName}>
        {this.getData()}
      </CustomCard>
    );
  }
}
