import React from "react";
import {
  FormTextarea,
  Switch,
  Table,
  Tbody,
  Thead,
  Tr,
  Td,
  ButtonIcon,
  FormSelect,
  Th,
} from "ui-kit-ck-consultant";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import CustomCard from "../../general/CustomCard";

import AuthContext from "../../../../context/AuthContext";


export default class RCPositionKnowParties extends React.Component {
  constructor(props) {
    super(props);
    this.displayName = "RCPositionKnowParties";
  }

  static contextType = AuthContext;

  addParty = () => {
    let tmpData = this.props.data;

    if (!tmpData.parties) {
      tmpData.parties = [];
    }

    tmpData.parties.push({
      party: "",
      position: 0,
      comment: "",
    });

    this.setData(tmpData);
  };

  deleteParty = (idx) => {
    let tmpData = this.props.data;

    tmpData.parties.splice(idx, 1);

    this.setData(tmpData);
  };

  updateParty = (idx, name, value) => {
    let tmpData = this.props.data;
    tmpData.parties[idx][name] = value;
    this.setData(tmpData);
  };

  setData = (tmpData) => {
    this.context.updateDataReport("rcPositionKnowParties", tmpData);
  };

  render() {
    return (
      <CustomCard
        title="Position connue des parties"
        name={this.displayName}
      >
        <>
          <Table>
            <Thead>
              <Tr>
                <Th>Partie</Th>
                <Th>Position</Th>
                <Th>Commentaire</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {this.props.data.parties
                ? this.props.data.parties.map((party, idx) => (
                    <Tr key={idx}>
                      <Td className="p-10 w-50">
                        <FormSelect
                          name="test"
                          value={party.party}
                          ignore={true}
                          init="Veuillez sélectionner une partie"
                          options={[...this.context.partiesOptions]}
                          onChange={({ target }) =>
                            this.updateParty(idx, "party", target.value)
                          }
                        />
                      </Td>
                      <Td className="p-10">
                        <Switch
                          className="mt-auto mb-auto"
                          value1="Non"
                          value2="Oui"
                          color1="#026fc2"
                          color2="#f2711c"
                          idxActive={party.position}
                          onChange={(type) =>
                            this.updateParty(idx, "position", type)
                          }
                        />
                      </Td>
                      <Td className="p-10 w-50">
                        {party.position ? (
                          <FormTextarea
                            title="Observations"
                            value={party.comment}
                            onChange={({ target }) =>
                              this.updateParty(idx, "comment", target.value)
                            }
                          />
                        ) : null}
                      </Td>
                      <Td className="p-10 w-50">
                        <ButtonIcon
                          className="red"
                          onClick={() => {
                            window.confirmCustom(
                              "Êtes-vous sûr de vouloir supprimer cette ligne ?",
                              (result) => {
                                if (result) {
                                  this.deleteParty(idx);
                                }
                              }
                            );
                          }}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </ButtonIcon>
                      </Td>
                    </Tr>
                  ))
                : null}
              <Tr>
                <Td colSpan={4} className="text-center">
                  <ButtonIcon
                    className="m-10"
                    isBackground
                    small
                    onClick={this.addParty}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </ButtonIcon>
                </Td>
              </Tr>
            </Tbody>
          </Table>
          <FormTextarea
            title="Observations"
            value={this.props.data.comment}
            onChange={({ target }) => {
              let tmpData = this.props.data;
              tmpData.comment = target.value;
              this.setData(tmpData);
            }}
          />
        </>
      </CustomCard>
    );
  }
}
