import axiosConfig from "../../utils/axiosConfig";

const getFiles = (data, callback) => {
  axiosConfig("folder/file/get-files", {
    data,
  })
    .then((res) => {
      callback(res.data);
    })
    .catch((err) => {
      callback({ success: false });
    });
};

const deleteFile = (data, callback) => {
  axiosConfig("folder/file/delete-file", {
    data,
  })
    .then((res) => {
      callback(res.data);
    })
    .catch((err) => {
      callback({ success: false });
    });
};

const addFile = (data, callback) => {
  if (navigator.onLine) {
    axiosConfig("folder/file/add-file", {
      data,
    })
      .then((res) => {
        callback(res.data);
      })
      .catch((err) => {
        callback({ success: false });
      });
  } else {
    alert("Une erreur est survenue");
  }
};

const updateFile = (data, callback) => {
  axiosConfig("folder/file/update-file", {
    data,
  })
    .then((res) => {
      callback(res.data);
    })
    .catch((err) => {
      callback({ success: false });
    });
};

export { getFiles, deleteFile, addFile, updateFile };
