import React from "react";
import {
  ButtonIcon,
  FormCheckbox,
  FormInput,
  Modal,
  Table,
  Tbody,
  Td,
  Tr,
  Th,
} from "ui-kit-ck-consultant";
import {
  faAlignJustify,
  faAlignLeft,
  faCaretDown,
  faCaretUp,
  faList,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

import CustomCard from "../../../../components/folder/general/CustomCard";

import AuthContext from "../../../../context/AuthContext";

const list = [
  "Suite à un mandat privé reçu de Madame/Monsieur …., propriétaire/acquéreur du véhicule ……concernant un désordre affectant ….",
  "Procéder à l’examen unilatéral du véhicule.",
  "Constater la ou les dommages.",
  "En fonction des constatations unilatérales, définir si un recours à l’encontre d’un tiers est envisageable et sur quel fondement.",
  "Rédiger un compte rendu.",
  "Suite à un mandat privé reçu de Madame/Monsieur …., propriétaire/acquéreur du véhicule ……concernant un désordre affectant ….",
  "Se rendre à l'expertise contradictoire diligentée par la partie adverse au ../../…., en assistance technique de Madame/Monsieur …..",
  "Procéder à l'examen du véhicule concerné afin d'en constater l'état général, de déceler ses antécédents, de décrire la panne et d'en déterminer la cause.",
  "Si elle résulte d'un vice caché, d'en fixer l'origine par rapport à la vente, et indiquer si le véhicule est impropre à sa destination.",
  "Chiffrer le montant des désordres.",
  "Rédiger un rapport d’expertise.",
  "Suite à un mandat reçu de l’assureur Protection Juridique de Madame/Monsieur …., propriétaire/acquéreur du véhicule ……concernant un désordre affectant ….",
  "Organiser une réunion d’expertise conviant l’ensemble des parties attachées au dossier. ",
  "Procéder à l'examen du véhicule concerné afin d'en constater l'état général, de déceler ses antécédents, de décrire la panne et d'en déterminer la cause.",
  "Si elle résulte d'un vice caché, d'en fixer l'origine par rapport à la vente, et indiquer si le véhicule est impropre à sa destination.",
  "Rechercher si les dysfonctionnements étaient apparents lors de l'acquisition du véhicule, et indiquer s'ils étaient décelables par une personne non avertie.",
  "Chiffrer le montant des réparations, et indiquer la valeur résiduelle du véhicule.",
  "Evaluer les préjudices annexes (immobilisation, frais de gardiennage…).",
  "Rédiger un rapport d’expertise.",
  "Suite à un mandat reçu de l’assureur Protection Juridique de Madame/Monsieur …., vendeur du véhicule d’occasion, concernant un désordre affectant ….",
  "Se rendre à l'expertise contradictoire diligentée par la partie adverse au ../../…., en assistance technique de Madame/Monsieur …..",
  "Procéder à l'examen du véhicule concerné afin d'en constater l'état général, de déceler ses antécédents, de décrire la panne et d'en déterminer la cause.",
  "Si elle résulte d'un vice caché, d'en fixer l'origine par rapport à la vente, et indiquer si le véhicule est impropre à sa destination.",
  "Rechercher si les dysfonctionnements étaient apparents lors de l'acquisition du véhicule, et indiquer s'ils étaient décelables par une personne non avertie.",
  "Chiffrer le montant des réparations, et indiquer la valeur résiduelle du véhicule.",
  "Evaluer les préjudices annexes (immobilisation, frais de gardiennage…).",
  "Rédiger un rapport d’expertise.",
  "Suite à un mandat reçu par l’assureur Responsabilité Civile et Professionnelle de la société …… intervenu le ../../… dans le cadre de « intervention succincte à définir »",
  "Assister l'assuré professionnel aux opérations d’expertises contradictoires diligentées par la partie adverse.",
  "Recueillir toutes informations, explications et pièces nécessaires à une appréciation exacte de la situation de fait et de droit.",
  "Décrire la panne ou l'opération d'entretien qui a conduit Madame/Monsieur … à confier son véhicule au garagiste. ",
  "Procéder à l'examen du véhicule afin d'en constater l'état général, de déceler ses antécédents, de décrire la panne et d'en déterminer la cause.",
  "Indiquer si la prestation de l’assuré professionnel était justifiée, nécessaire, adaptée et si elle a été réalisée conformément aux règles de l'art.",
  "Décrire les dommages apparus dans les suites de son intervention et chiffrer le coût des réparations nécessaires pour y remédier.",
  "Indiquer la valeur résiduelle du véhicule.",
  "Dans l'hypothèse où l'intervention du garagiste n'a pas eu lieu conformément aux règles de l'art, dire s'il existe un lien de causalité entre sa faute et les dommages décrits.",
  "Rédiger un rapport d’expertise.",
];

export default class PurposeMission extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isModal: false };
    this.displayName = "PurposeMission";
  }

  static contextType = AuthContext;

  handleCheckboxChange = (e) => {
    const { name, checked } = e.target;

    let tmpData = this.props.data;
    tmpData.answers[name] = checked;

    this.setData(tmpData);
  };

  componentDidMount() {
    this.initData();
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
      this.initData();
    }
  }

  initData = () => {
    if (!this.props.data.hasOwnProperty("objects")) {
      let objects = [];

      if (this.context.type === "Responsabilité Civile et professionnelle") {
        objects = [
          {
            text: "Organiser si nécessaire une réunion d’examen contradictoire",
          },
          {
            text: "Convoquer régulièrement les parties en leur exposant brièvement l'objet d’expertise.",
          },
          {
            text: "Assister aux opérations d’expertises contradictoires mises en place par la partie adverse.",
          },
          { text: "Déterminer la cause des dommages." },
          { text: "Chiffrer la remise en conformité." },
          { text: "Déterminer la ou les responsabilités." },
          { text: "Déposer nos conclusions." },
        ];
      } else {
        objects = [
          {
            text: "Organiser si nécessaire une réunion d’examen contradictoire",
          },
          {
            text: "Convoquer régulièrement les parties en leur exposant brièvement l'objet de votre expertise.",
          },

          {
            text: "Assister aux opérations d’expertises contradictoires mises en place par la partie adverse.",
          },

          { text: "Déterminer la cause des dommages." },
          { text: "Chiffrer la remise en conformité." },
          { text: "Déterminer la ou les responsabilités." },
          { text: "Trouver si possible une ou des solutions amiables." },
          { text: "Déposer nos conclusions." },
        ];
      }

      let answers = {};

      const tmpData = {
        objects,
        answers,
      };

      this.setData(tmpData);
    }
  };

  setData = (tmpData) => {
    if (this.context.updateDataReport) {
      this.context.updateDataReport("purposeMission", tmpData);
    } else {
      this.props.setData("dataPurposeMission", tmpData);
    }
  };

  render() {
    return (
      <>
        <CustomCard
          title="Objet de la mission"
          name={this.displayName}
          action={
            this.context.updateDataReport ? (
              <ButtonIcon
                className="blue"
                small
                info={
                  this.context.getJustifyCard(this.displayName)
                    ? "Aligner à gauche"
                    : "Justifier le texte"
                }
                onClick={() => this.context.updateJustifyCard(this.displayName)}
              >
                <FontAwesomeIcon
                  icon={
                    this.context.getJustifyCard(this.displayName)
                      ? faAlignLeft
                      : faAlignJustify
                  }
                />
              </ButtonIcon>
            ) : null
          }
        >
          <Table>
            <Tbody>
              {this.props.data && this.props.data.hasOwnProperty("objects")
                ? this.props.data.objects.map((object, idx) => (
                    <Tr key={idx}>
                      <Th className="p-10">{idx + 1}</Th>
                      <Td>
                        <div className="d-flex no-wrap">
                          {this.props.data.objects.length !== idx + 1 ? (
                            <ButtonIcon
                              small
                              className="blue mt-auto mb-auto"
                              onClick={() => {
                                let tmpData = this.props.data;

                                const tmpLine =
                                  this.props.data.objects[idx + 1];

                                tmpData.objects[idx + 1] =
                                  this.props.data.objects[idx];
                                tmpData.objects[idx] = tmpLine;

                                this.setData(tmpData);
                              }}
                            >
                              <FontAwesomeIcon icon={faCaretDown} />
                            </ButtonIcon>
                          ) : null}
                          {idx ? (
                            <ButtonIcon
                              small
                              className="blue mt-auto mb-auto"
                              onClick={() => {
                                let tmpData = this.props.data;

                                const tmpLine =
                                  this.props.data.objects[idx - 1];

                                tmpData.objects[idx - 1] =
                                  this.props.data.objects[idx];
                                tmpData.objects[idx] = tmpLine;

                                this.setData(tmpData);
                              }}
                            >
                              <FontAwesomeIcon icon={faCaretUp} />
                            </ButtonIcon>
                          ) : null}
                        </div>
                      </Td>
                      <Td className="text-center">
                        <FormCheckbox
                          className="m-0"
                          name={idx}
                          checked={!!this.props.data.answers[idx]}
                          onChange={this.handleCheckboxChange}
                        />
                      </Td>
                      <Td className="w-100">
                        {object.isEdit ? (
                          <>
                            <FormInput
                              className="ml-5 mr-5 mt-auto mb-auto"
                              styleInput={{
                                border: "none",
                                padding: "0 10px",
                                fontSize: "13px",
                                height: "36px",
                              }}
                              value={object.text}
                              onChange={({ target }) => {
                                let tmpData = this.props.data;
                                tmpData.objects[idx].text = target.value;
                                this.setData(tmpData);
                              }}
                            />
                          </>
                        ) : object.isEdit ? (
                          ""
                        ) : (
                          object.text
                        )}
                      </Td>
                      <Td className="text-center">
                        <ButtonIcon
                          small
                          className="red mt-auto mb-auto"
                          onClick={() => {
                            window.confirmCustom(
                              "Êtes-vous sûr de vouloir supprimet cet objet ?",
                              (result) => {
                                if (result) {
                                  let tmpData = this.props.data;
                                  delete tmpData.answers[idx];
                                  tmpData.objects.splice(idx, 1);
                                  this.setData(tmpData);
                                }
                              }
                            );
                          }}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </ButtonIcon>
                      </Td>
                    </Tr>
                  ))
                : null}
            </Tbody>
          </Table>

          <div
            className="d-flex"
            style={{
              gap: 10,
            }}
          >
            <ButtonIcon
              small
              info="Liste"
              isBackground
              className="ml-auto"
              onClick={() => {
                this.setState({ isModal: true });
              }}
            >
              <FontAwesomeIcon icon={faList} />
            </ButtonIcon>
            <ButtonIcon
              small
              info="Ajouter une ligne"
              isBackground
              className=""
              onClick={() => {
                let tmpData = this.props.data;
                tmpData.objects.push({ text: "", isEdit: true });
                this.setData(tmpData);
              }}
            >
              <FontAwesomeIcon icon={faPlus} />
            </ButtonIcon>
          </div>
        </CustomCard>
        <Modal
          title="Liste"
          show={this.state.isModal}
          large
          onClose={() => this.setState({ isModal: false })}
        >
          <Table>
            <Tbody>
              {list.map((element, idx) => (
                <Tr key={idx}>
                  <Td>{element}</Td>
                  <Td>
                    <ButtonIcon
                      small
                      className="blue"
                      onClick={() => {
                        let tmpData = this.props.data;
                        tmpData.objects.push({ text: element, isEdit: true });
                        this.setData(tmpData);
                      }}
                    >
                      <FontAwesomeIcon icon={faCheck} />
                    </ButtonIcon>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Modal>
      </>
    );
  }
}
