import React from "react";
import { Button, ButtonIcon, Loader, Nav } from "ui-kit-ck-consultant";
import {
  faCalendar,
  faEnvelope,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "react-flexbox-grid";

import Header from "../../components/home/Header";
import Filters from "../../components/home/Filters";
import Memory from "../../components/home/Memory";
import Body from "../../components/home/Body";
import ModalAddBlankFolder from "../../components/home/ModalAddBlankFolder";
import ModalAddFolder from "../../components/home/ModalAddFolder";

import { getFolders, countFolders } from "../../requests/home";
import { getFolder } from "../../requests/folder";

import { checkFolderSync } from "../../utils/general";

import AuthContext from "../../context/AuthContext";
import ModalHistoryPostal from "../../components/home/ModalHistoryPostal";

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      folders: [],
      isModalAddBlankFolder: false,
      isModalAddFolder: false,
      isModalHistoryPostal: false,
      countFolders: {},
      isDisconnected: false,
      // inputs
      search: "",
      client: "",
      expert: "",
      agency: "",
      region: "",
      company: "",
      state: "",
      type: "",
      isAllFolders: false,
      isOfflineFolders: false,
      isLockFolders: false,
    };
  }

  static contextType = AuthContext;

  handleInputChange = (e) => {
    const { value, name } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => {
        localStorage.setItem(name, value);
      }
    );
  };

  handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    this.setState(
      {
        [name]: checked,
      },
      () => {
        localStorage.setItem(name, checked ? 1 : 0);
        this.getFolders();
      }
    );
  };

  componentDidMount() {
    const data = {
      search: localStorage.getItem("search") || "",
      company: localStorage.getItem("company") || "",
      state: localStorage.getItem("state") || "",
      type: localStorage.getItem("type") || "",
      isAllFolders: localStorage.getItem("isAllFolders") === "1",
      isOfflineFolders: localStorage.getItem("isOfflineFolders") === "1",
      isLockFolders: localStorage.getItem("isLockFolders") === "1",
    };

    this.setState(
      {
        ...data,
        agency: this.context.agencyId,
        region: this.context.regionId,
      },
      () => {
        this.getFolders();
        this.countFolders();
      }
    );

    this.handleCheckOnline();
    this.onlineInterval = setInterval(this.handleCheckOnline, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.onlineInterval);
  }

  handleCheckOnline = () => {
    if (!navigator.onLine && !this.state.isDisconnected) {
      this.setState({ isDisconnected: true });
    } else if (navigator.onLine && this.state.isDisconnected) {
      this.setState({ isDisconnected: false });
    }
  };

  getFolders = () => {
    this.setState(
      {
        isLoad: true,
      },
      () => {
        let isUpdate = false;

        getFolders({ isAllFolders: this.state.isAllFolders }, (result) => {
          if (result.success) {
            let folders = result.data.folders;

            Promise.all(
              folders.map((folder) => {
                return new Promise((resolve) => {
                  checkFolderSync(folder, (resultCheck) => {
                    folder.isServer = resultCheck.isServer;
                    folder.isLocal = resultCheck.isLocal;
                    folder.isOffline = resultCheck.success;

                    if (
                      !this.state.isDisconnected &&
                      (!folder.isOffline ||
                        folder.isServer ||
                        folder.isLocal) &&
                      (folder.userId === this.context.id ||
                        folder.assistantId === this.context.id ||
                        folder.expertId === this.context.id)
                    ) {
                      getFolder(
                        {
                          ...folder,
                          isSoftware: this.context.isSoftware,
                        },
                        () => {
                          isUpdate = true;
                          resolve(folder);
                        }
                      );
                    } else {
                      resolve(folder);
                    }
                  });
                });
              })
            ).then((resultPromise) => {
              if (isUpdate) {
                this.getFolders();
              } else {
                this.setState({
                  isLoad: false,
                  folders: resultPromise,
                });
              }
            });
          } else {
            this.setState({
              isLoad: false,
              folders: [],
            });
          }
        });
      }
    );
  };

  countFolders = () => {
    countFolders((result) => {
      if (result.success) {
        this.setState({ countFolders: result.data });
      } else {
        this.setState({
          countFolders: {},
        });
      }
    });
  };

  render() {
    const experts = this.state.folders
      .map((folder) => ({
        text: folder.expertInitial,
        value: folder.expertInitial,
      }))
      .filter(
        (arr, index, self) =>
          index === self.findIndex((t) => t.value === arr.value) && arr.value
      )
      .sort((a, b) => a.value.localeCompare(b.value));

    return (
      <AuthContext.Provider
        value={{
          ...this.context,
          isDisconnected: this.state.isDisconnected,
        }}
      >
        <Nav className="mb-10 mb-30-xl" title="Tableau de bord">
          <ButtonIcon
            isBackground
            className="mr-10"
            disabled={this.state.isDisconnected}
            onClick={() =>
              this.setState({
                isModalHistoryPostal: true,
              })
            }
          >
            <FontAwesomeIcon icon={faEnvelope} />
          </ButtonIcon>
          <ButtonIcon
            isBackground
            className="mr-10"
            disabled={this.state.isDisconnected}
          >
            <FontAwesomeIcon icon={faCalendar} />
          </ButtonIcon>
          <Button
            text="Nouveau dossier"
            onClick={() =>
              this.setState({
                isModalAddFolder: true,
              })
            }
            disabled={this.state.isDisconnected}
          >
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </Nav>
        <Header
          countFolders={this.state.countFolders}
          updateStateFilter={(id) => this.setState({ state: id })}
        />
        <Row>
          <Col xs={12} md={4} lg={2}>
            <Filters
              search={this.state.search}
              client={this.state.client}
              expert={this.state.expert}
              agency={this.state.agency}
              region={this.state.region}
              experts={experts}
              company={this.state.company}
              state={this.state.state}
              type={this.state.type}
              isAllFolders={this.state.isAllFolders}
              isOfflineFolders={this.state.isOfflineFolders}
              isLockFolders={this.state.isLockFolders}
              handleInputChange={this.handleInputChange}
              handleCheckboxChange={this.handleCheckboxChange}
            />
          </Col>
          <Col xs={12} md={8} lg={10}>
            <Memory />
            <Body
              folders={this.state.folders}
              getFolders={this.getFolders}
              search={this.state.search}
              client={this.state.client}
              expert={this.state.expert}
              agency={this.state.agency}
              region={this.state.region}
              company={this.state.company}
              state={this.state.state}
              type={this.state.type}
              isAllFolders={this.state.isAllFolders}
              isOfflineFolders={this.state.isOfflineFolders}
              isLockFolders={this.state.isLockFolders}
            />
          </Col>
        </Row>
        <ModalAddBlankFolder
          folders={this.state.folders}
          show={this.state.isModalAddBlankFolder}
          onClose={(isReload) =>
            this.setState({ isModalAddBlankFolder: false }, () => {
              if (isReload) {
                this.getFolders();
              }
            })
          }
        />
        <ModalAddFolder
          folders={this.state.folders}
          show={this.state.isModalAddFolder}
          onClose={(isReload = false) =>
            this.setState({ isModalAddFolder: false }, () => {
              if (isReload) {
                this.getFolders();
              }
            })
          }
          onOpenModalAddBlankFolder={() =>
            this.setState({
              isModalAddFolder: false,
              isModalAddBlankFolder: true,
            })
          }
        />
        {this.state.isModalHistoryPostal && (
          <ModalHistoryPostal
            mailevaData={this.context.mailevaData}
            show={this.state.isModalHistoryPostal}
            onClose={() => this.setState({ isModalHistoryPostal: false })}
          />
        )}
        <Loader show={this.state.isLoad} />
      </AuthContext.Provider>
    );
  }
}
